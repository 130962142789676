<template>
	<simple-pay-layout>
		<el-main class="form-wrapper" :style="simplePayCssProps">
			<el-alert
				v-show="!simplePayId?.length"
				title="Invalid url: No ID detected"
				type="error"
				show-icon
				:closable="false"
			></el-alert>
			<el-alert
				v-show="hasBanner"
				:title="bannerMessage"
				:type="bannerType"
				show-icon
				@close="clearBanner"
			></el-alert>
			<el-row
				v-show="
					simplePayId?.length &&
					(userView === 'card' || userView === 'ach')
				"
				class="input-group pay-method-wrap mt-10 mb-20"
				data-payment-method
				:gutter="15"
			>
				<el-col :sm="6" :xs="16">
					<h2
						v-show="userView === 'card' || userView === 'ach'"
						class="mt-5 mb-5"
					>
						Payment Method
						<el-popover
							placement="bottom"
							title="Accepted Payment Types"
							width="200"
							trigger="click"
							:content="
								acceptedPaymentTypes
									.toString()
									.replace(/,/g, ', ')
							"
							popper-class="card-tooltip"
						>
							<template #reference>
								<span
									v-show="
										hasCardForm &&
										acceptedPaymentTypes.length
									"
									class="info-icon"
								>
									&#8505;
								</span>
							</template>
						</el-popover>
					</h2>
				</el-col>
				<el-col
					v-show="
						hasCardForm &&
						hasAchForm &&
						(userView === 'card' || userView === 'ach')
					"
					:sm="18"
					:xs="24"
				>
					<el-radio-group
						v-model="userView"
						class="choose-form"
						size="small"
					>
						<el-radio label="card" border data-cy="card-form-radio"
							>Card</el-radio
						>
						<el-radio label="ach" border data-cy="ach-form-radio"
							>ACH</el-radio
						>
					</el-radio-group>
				</el-col>
				<div
					class="mfes-trustmark"
					data-type="102"
					data-width="77"
					data-height="32"
				></div>
			</el-row>
			<simple-pay-card-form
				v-if="hasCardForm && simplePayId?.length && initComplete"
				v-show="userView === 'card'"
			></simple-pay-card-form>
			<simple-pay-ach-form
				v-if="hasAchForm && simplePayId?.length && initComplete"
				v-show="userView === 'ach'"
			></simple-pay-ach-form>
			<simple-pay-success
				v-show="userView === 'success'"
				@reset-simplepay="resetForm"
			></simple-pay-success>
			<simple-pay-inactive
				v-show="userView === 'inactive'"
			></simple-pay-inactive>
		</el-main>
		<transition name="fade">
			<el-card v-show="!hideGdpr" id="gdpr-opt-out">
				<i class="el-icon-close" @click="dismissGdprBanner"></i>
				<small
					>By continuing to use this site or clicking "Accept" you
					consent to our website's use of cookies.</small
				>
				<div class="btn-wrap">
					<el-button
						class="reject-btn"
						type="info"
						size="mini"
						plain
						@click="optOutMixpanel"
						>Decline</el-button
					>
					<el-button
						class="accept-btn"
						type="primary"
						size="mini"
						@click="dismissGdprBanner"
						>Accept</el-button
					>
				</div>
			</el-card>
		</transition>
	</simple-pay-layout>
</template>

<script lang="ts">
import {
	defineComponent,
	ref,
	computed,
	onMounted,
	getCurrentInstance
} from 'vue'
import { useStore } from '../store'
import SimplePayLayout from '../global-components/layouts/SimplePayLayout.vue'
import SimplePayCardForm from '../global-components/SimplePayCardForm.vue'
import SimplePayAchForm from '../global-components/SimplePayAchForm.vue'
import SimplePaySuccess from '../global-components/SimplePaySuccess.vue'
import SimplePayInactive from '../global-components/SimplePayInactive.vue'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'
import router from '@/router'

export default defineComponent({
	name: 'SimplePayHostedPaymentView',
	components: {
		SimplePayLayout,
		SimplePayCardForm,
		SimplePayAchForm,
		SimplePaySuccess,
		SimplePayInactive
	},
	setup() {
		const store = useStore()
		// initialize as null string because certain props look at length if there's no id in url
		const simplePayId = ref('null')
		const initComplete = ref(false)
		const distinctId = ref('')
		const hideGdpr = ref(true)

		const hasCardForm = computed((): boolean => {
			return (
				store.state.iqMerchantData.simplePaySettings.hasCardForm ===
				true
			)
		})
		const hasAchForm = computed((): boolean => {
			return (
				store.state.iqMerchantData.simplePaySettings.hasAchForm === true
			)
		})
		const formIsActive = computed((): boolean => {
			return (
				store.state.iqMerchantData.simplePaySettings.isActive === true
			)
		})
		const userView = computed({
			get: (): string => store.state.simplePayUserView,
			set: (selectedView: string): void => {
				// default to card if an invalid userView is passed
				selectedView =
					selectedView === 'ach' ||
					selectedView === 'inactive' ||
					selectedView === 'success'
						? selectedView
						: 'card'
				// pass userView to mixpanel
				formViews(selectedView)
				// set userView in store
				store.commit('simplePayUserView', selectedView)
			}
		})
		const hasBanner = computed((): boolean => {
			return store.state.iqMerchantData.simplePaySettings.hasBanner
		})
		const bannerType = computed((): string => {
			return store.state.iqMerchantData.simplePaySettings.bannerType
		})
		const bannerMessage = computed((): string => {
			return store.state.iqMerchantData.simplePaySettings.bannerMessage
		})
		const acceptedPaymentTypes = computed((): string[] => {
			let cards =
				store.state.iqMerchantData.simplePaySettings.acceptedCards
			let formattedCards: string[] = []

			cards = Array.isArray(cards) ? cards : []

			function stringUpper(card: string): string {
				return card.toUpperCase()
			}

			if (cards.length) {
				let uppercaseCards = cards.map(stringUpper)
				// pushing values into the array manually to enforce a specific order and allow for varied capitalization

				if (uppercaseCards.indexOf('VISA') > -1) {
					formattedCards.push('Visa')
				}

				if (uppercaseCards.indexOf('MASTERCARD') > -1) {
					formattedCards.push('MasterCard')
				}

				if (uppercaseCards.indexOf('DISCOVER') > -1) {
					formattedCards.push('Discover')
				}

				if (uppercaseCards.indexOf('AMERICAN EXPRESS') > -1) {
					formattedCards.push('American Express')
				}

				if (store.state.iqMerchantData.simplePaySettings.hasAchForm) {
					formattedCards.push('ACH')
				}
			}

			return formattedCards
		})
		const simplePayCssProps = computed(() => {
			return {
				'--body-font':
					store.state.iqMerchantData.simplePaySettings.bodyFont ??
					'Avenir',
				'--headline-font':
					store.state.iqMerchantData.simplePaySettings.headerFont ??
					'Avenir',
				'--body-font-color':
					store.state.iqMerchantData.simplePaySettings
						.bodyFontColor ?? '#6d6c6b',
				'--headline-font-color':
					store.state.iqMerchantData.simplePaySettings
						.headerFontColor ?? '#6d6c6b',
				'--bg-color':
					store.state.iqMerchantData.simplePaySettings
						.backgroundColor,
				'--form-background':
					store.state.iqMerchantData.simplePaySettings
						.formBackgroundColor
			}
		})

		function getUrlParameter(paramName: string): string {
			paramName = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null
				? 'null_id'
				: decodeURIComponent(results[1].replace(/\+/g, ' '))
		}
		function initForm(): void {
			initComplete.value = true // card and ach forms v-if condition is met
			let displayedForm = 'inactive'
			// check if cookie from previous visit exists
			const isReturningUser = hasVisitorCookie()

			if (!isReturningUser) {
				// if user has not visited before, reveal gdpr banner
				hideGdpr.value = false
				// set the cookie so the banner will stay hidden on next visit
				setVisitorCookie()
			}

			if (formIsActive.value) {
				displayedForm =
					hasAchForm.value && !hasCardForm.value ? 'ach' : 'card'
			}

			userView.value = displayedForm // selected form v-show condition is met
			// get that initial view count
		}
		async function resetForm() {
			await store.dispatch('showLoadingScreen')
			// set initComplete to false again in case the user is submitting a second payment
			// this will force the card and ach forms to re-mount
			initComplete.value = false
			await store.dispatch('clearTransactionDetails')
			initForm()
		}
		function optOutMixpanel(): void {
			mp.optUserOut()
			dismissGdprBanner()
		}
		function dismissGdprBanner(): void {
			hideGdpr.value = true
		}
		function setVisitorCookie(): void {
			localStorage.setItem('prevSimplepayUser', 'true')
		}
		function hasVisitorCookie(): boolean {
			return localStorage.getItem('prevSimplepayUser') === 'true'
		}
		function clearBanner(): void {
			store.commit('clearBanner')
		}
		function formViews(selectedView: string) {
			// capture view changes after page is loaded
			// adds view count to users profile in mixpanel
			switch (selectedView) {
				case 'card':
					mp.cardViewCountPeopleProp()
					mp.cardFormPageViewTrack()
					break
				case 'ach':
					mp.achViewCountPeopleProp()
					mp.achFormPageViewTrack()
					break
				case 'inactive':
					mp.inactiveViewCountPeopleProp()
					mp.inactivePageViewTrack(simplePayId.value)
					break
				case 'success':
					break
				default:
					break
			}
		}

		onMounted(async () => {
			simplePayId.value = getUrlParameter('id')

			// identifies user, associates the user with the simplepay group, tracks page view
			const app = getCurrentInstance()
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
			await store.dispatch('setDistinctId', distinctId.value)

			mp.setSimplePayGroupId(simplePayId.value)

			try {
				if (simplePayId.value === 'null_id') {
					router.push({ name: 'sp-page-not-found' })
					mp.pageNotFoundPageViewTrack(simplePayId.value)
				}
				await store.dispatch('initSimplePay', simplePayId.value)
				// initForm() will initialize SimplePayCardForm and/or SimplePayAchForm due to the v-if directive in the template.
				// Those child components require the PublicApiKey to be set in order to initialize the tokenizer,
				// so initForm() needs to be fired after the merchant data has been retrieved from the apis and set in the store.
				initForm()
			} catch (ex) {
				console.log('init failed', ex)
			}
		})

		return {
			simplePayId,
			hasCardForm,
			hasAchForm,
			formIsActive,
			initComplete,
			userView,
			hasBanner,
			bannerType,
			bannerMessage,
			acceptedPaymentTypes,
			simplePayCssProps,
			resetForm,
			getUrlParameter,
			clearBanner,
			hideGdpr,
			optOutMixpanel,
			dismissGdprBanner
		}
	}
})
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.el-popover.card-tooltip {
	font-size: 12px;
	padding: 14px 16px;
	word-break: break-word;
	text-align: left;
	max-width: 60vw;
	border: 1px solid $blue;

	.el-popover__title {
		color: $blue;
		font-size: 14px;
		margin-bottom: 8px;
	}

	.el-popper__arrow::before {
		border-color: $blue;
	}
}
</style>

<style lang="scss" scoped>
:deep(.el-main) {
	// form background color
	background-color: var(--form-background) !important;
}
.form-wrapper {
	:deep(*) {
		font-family: var(--body-font);
		color: var(--body-font-color);
	}
	:deep(.el-form-item__error) {
		color: #ff0000;
	}

	:deep(h2) {
		font-family: var(--headline-font);
		color: var(--headline-font-color);
	}
	:deep(.el-form-item.is-required:not(.is-no-asterisk)
			> .el-form-item__label:before) {
		color: #ff0000;
	}

	.pay-method-wrap {
		position: relative;

		.choose-form {
			margin-bottom: 0;
			color: var(--body-font-color);
		}

		.mfes-trustmark {
			right: 7px;
			top: 0;
			position: absolute;
		}
	}

	:deep(.el-input__inner) {
		border-color: var(--body-font-color);
		border: 1px solid;
	}

	:deep(.el-radio, .el-radio:after) {
		border-color: var(--body-font-color);
	}

	:deep(.el-radio__input.is-checked
			+ .el-radio__label, .el-radio__inner:hover) {
		color: var(--body-font-color);
	}

	:deep(.el-textarea__inner) {
		border: 1px solid var(--body-font-color);
	}

	:deep(.el-checkbox__inner) {
		border: 1px solid var(--body-font-color);
	}

	:deep(.el-radio-group
			.el-radio
			.el-radio__input.is-checked
			.el-radio__inner) {
		border: var(--body-font-color);
		background: var(--body-font-color);
	}

	:deep(.el-button--primary.is-disabled) {
		color: var(--body-font-color);
		background-color: var(--bg-color);
		border-color: var(--bg-color);
		opacity: 0.5;
	}

	:deep(.info-icon) {
		border: 1px solid var(--body-font-color);
	}

	:deep(.el-select .el-input .el-select__caret) {
		color: var(--body-font-color);
	}

	:deep(.el-input__inner::-webkit-input-placeholder) {
		color: var(--body-font-color);
		opacity: 0.6;
	}

	// payment button override
	:deep(.el-button--primary:not(.is-plain), .el-button--primary:not(.is-plain):hover, .el-button--primary:not(.is-plain):focus, .el-button--primary:not(.is-plain):active) {
		background-color: var(--bg-color);
		border-color: var(--bg-color);
	}
	:deep(.el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover) {
		color: #fff;
		background-color: var(--bg-color);
		border-color: var(--bg-color);
		opacity: 0.5;
	}
}

#gdpr-opt-out {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 0 10%;
	z-index: 99;
	box-sizing: border-box;
	border-radius: 0;

	:deep(.el-card__body) {
		padding: 25px 20px;
	}

	.el-icon-close {
		display: block;
		font-size: 20px;
		line-height: 1em;
		position: absolute;
		top: 0;
		right: 0;
		color: #888;
		padding: 7px;
		cursor: pointer;

		&:hover {
			color: $blue;
		}
	}

	small {
		display: inline-block;
		width: calc(100% - 215px);
		line-height: 1.25em;
		color: #555;
	}

	.btn-wrap {
		display: inline-block;
		width: 215px;
		box-sizing: border-box;
		padding-left: 40px;
	}

	.el-button {
		width: 80px;
	}

	.reject-btn {
		color: #777;
		margin-right: 5px;

		&:hover,
		&:active,
		&:focus {
			color: #555;
			border-color: #ababab;
			background: #f4f4f5;
		}
	}
}

@media screen and (max-width: 767px) {
	.choose-form {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.mfes-trustmark.loaded {
		top: 18px;
		right: 25px;
		width: 60px;
		height: 25px;
	}
}

@media screen and (max-width: 640px) {
	#gdpr-opt-out {
		padding: 0;

		small {
			display: block;
			width: 100%;
			margin: 5px 0 15px;
		}

		.btn-wrap {
			display: block;
			width: 100%;
			text-align: center;
			padding-left: 0;
			top: 0;
		}

		.el-button {
			width: 100px;
		}
	}
}
</style>
