<template>
	<el-form
		ref="simplePayAchForm"
		label-position="top"
		:model="formData"
		:rules="formValidationRules"
		size="small"
	>
		<div class="input-group" data-ach-details>
			<div id="ach-container"></div>
			<p class="error-text">{{ tokenizerError }}</p>
		</div>
		<div class="input-group" data-billing-info>
			<h2>Billing Information</h2>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="12">
					<el-form-item label="First Name" prop="firstName">
						<el-input
							id="firstName"
							v-model="formData.firstName"
							type="text"
							data-cy="ach-form-first-name"
							@change="trackFormTime"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12">
					<el-form-item label="Last Name" prop="lastName">
						<el-input
							id="lastName"
							v-model="formData.lastName"
							type="text"
							data-cy="ach-form-last-name"
							@change="trackFormTime"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="15">
				<el-col v-show="hasCompany" :xs="24" :sm="24">
					<el-form-item
						:label="
							companyRequired
								? companyLabel
								: companyLabel + ' (optional)'
						"
						prop="company"
					>
						<el-input
							id="company"
							v-model="formData.company"
							type="text"
							data-cy="ach-form-company"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="16">
					<el-form-item label="Address Line 1" prop="address">
						<el-input
							id="address"
							v-model="formData.address"
							type="text"
							data-cy="ach-form-address"
						></el-input>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="8">
					<el-form-item ref="address2" label="Address Line 2">
						<el-input
							id="address2"
							v-model="formData.address2"
							type="text"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :md="24">
					<el-form-item :label="'Country'" prop="country">
						<el-select
							id="country"
							v-model="formData.country"
							:popper-append-to-body="false"
							autocomplete="chrome-off"
							filterable
							data-cy="ach-form-country-select"
							@change="updateStateList"
						>
							<el-option
								v-for="country in countryList"
								:key="country.code"
								:label="country.name"
								:value="country.code"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8">
					<el-form-item label="City" prop="city">
						<el-input
							id="city"
							v-model="formData.city"
							type="text"
							data-cy="ach-form-city"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8">
					<el-form-item
						label="State"
						prop="state"
						:rules="stateValidationRules"
					>
						<el-select
							id="state"
							v-model="formData.state"
							filterable
							:popper-append-to-body="false"
							data-cy="ach-form-state-select"
							autocomplete="chrome-off"
							@blur="validateDropdown"
						>
							<el-option
								v-for="state in stateList"
								:key="state.code"
								:label="state.name"
								:value="state.code"
								data-cy="ach-form-state-option"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8">
					<el-form-item
						label="Postal Code"
						prop="postalCode"
						:rules="postalCodeValidationRules"
					>
						<el-input
							id="postalCode"
							v-model="formData.postalCode"
							type="text"
							data-cy="ach-form-postalCode"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="15">
				<el-col :xs="24" :sm="12">
					<el-form-item
						label="Email"
						prop="email"
						data-cy="ach-form-email-label"
					>
						<el-input
							id="email"
							v-model="formData.email"
							type="text"
							data-cy="ach-form-email"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12">
					<el-form-item label="Phone" prop="phone">
						<el-input
							id="phone"
							v-model="formData.phone"
							type="text"
							placeholder="(numbers only)"
							data-cy="ach-form-phone"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</div>
		<div v-show="showSecurityInfo" class="input-group" data-security-fields>
			<h2>Security Information</h2>
			<el-row v-if="hasDLFields" :gutter="15">
				<el-col :xs="24" :sm="12">
					<el-form-item label="Driver's License State" prop="dlState">
						<el-select
							id="dlState"
							v-model="formData.dlState"
							filterable
							:popper-append-to-body="false"
							data-cy="ach-form-dlState-select"
							autocomplete="chrome-off"
						>
							<el-option
								v-for="state in stateList"
								:key="state.code"
								:label="state.name"
								:value="state.code"
								data-cy="ach-form-dlState-option"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12">
					<el-form-item
						label="Driver's License Number"
						prop="dlNumber"
					>
						<el-input
							id="dlNumber"
							v-model="formData.dlNumber"
							type="text"
							data-cy="ach-form-dlNumber"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</div>
		<div
			v-show="showAdditionalInfo"
			class="input-group"
			data-additional-fields
		>
			<h2>{{ additionalInfoHeader }}</h2>
			<el-row :gutter="15">
				<el-col v-show="hasOrderId && !formData.hideOrder" :xs="24" :sm="12">
					<el-form-item
						:label="
							orderIdRequired
								? orderIdLabel
								: orderIdLabel + ' (optional)'
						"
						prop="orderId"
					>
						<el-input
							id="orderId"
							v-model="formData.orderId"
							type="text"
							data-cy="ach-form-order-id"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col v-show="hasPoNumber && !formData.hidePO" :xs="24" :sm="12">
					<el-form-item
						:label="
							poNumberRequired
								? poNumberLabel
								: poNumberLabel + ' (optional)'
						"
						prop="poNumber"
					>
						<el-input
							id="poNumber"
							v-model="formData.poNumber"
							type="text"
							data-cy="ach-form-poNumber"
						></el-input>
					</el-form-item>
				</el-col>
				<!-- Custom Fields -->
				<el-col
					v-for="field in formData.customFields"
					:key="field.id"
					:xs="24"
					:sm="12"
				>
					<el-form-item
						:label="
							field.required
								? field.name
								: field.name + ' (optional)'
						"
						:prop="formData.customFieldValues[field.id]"
						:rules="{
							required: field.required,
							message: field.name + ' is required',
							trigger: 'blur'
						}"
					>
						<!-- text input -->
						<el-input
							v-if="field.type === 'text'"
							v-model="formData.customFieldValues[field.id]"
							type="text"
						></el-input>
						<!-- dropdown -->
						<el-select
							v-if="field.type === 'select'"
							v-model="formData.customFieldValues[field.id]"
						>
							<el-option
								v-for="(option, index) in field.values"
								:key="index"
								:label="option.name"
								:value="option.value"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<!-- End Custom Fields -->
				<el-col v-show="hasNote" :span="24">
					<el-form-item label="Note (optional)" prop="note">
						<el-input
							id="note"
							v-model="formData.note"
							type="textarea"
							autosize
							maxlength="250"
							data-cy="ach-form-note"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</div>
		<div class="input-group" data-amount-fields>
			<el-row :gutter="15">
				<el-col
					:xs="{ span: 24, offset: 0 }"
					:sm="{ span: 12, offset: 12 }"
				>
					<el-form-item label="Amount" prop="amount">
						<el-input
							id="amount"
							v-model="formData.amount"
							type="number"
							step="0.01"
							placeholder="$"
							data-cy="ach-form-amount"
							@keypress="
								preventExcessDigitsOnAmount(
									formData.amount,
									$event
								)
							"
						></el-input>
					</el-form-item>
					<p v-if="hasConvenienceFee" class="mb-5">
						Convenience Fee:
						<span class="float-r">{{
							convenienceFeeDisplayText
						}}</span>
					</p>
					<p v-if="hasServiceFee" class="mb-5">
						Service Fee:
						<span class="float-r">{{ serviceFeeDisplayText }}</span>
					</p>
					<p class="bold-text large-text mb-10">
						Total:
						<span class="bold-text large-text float-r">{{
							totalAmountDisplayText
						}}</span>
					</p>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col
					:xs="{ span: 24, offset: 0 }"
					:sm="{ span: 12, offset: 12 }"
				>
					<el-checkbox
						v-model="formData.shouldEmailReceipt"
						class="mt-10"
						data-cy="ach-form-shouldEmailReceipt"
						>Email me a copy of my receipt</el-checkbox
					>
				</el-col>
				<el-col
					v-if="hasVaultField"
					:xs="{ span: 24, offset: 0 }"
					:sm="{ span: 12, offset: 12 }"
				>
					<el-checkbox
						v-model="formData.shouldVaultCustomer"
						class="mt-10"
						data-cy="ach-form-hasVaultField"
						>I authorize
						{{
							merchantName !== '' ? merchantName : 'this merchant'
						}}
						to save my payment method to use for recurring
						billing</el-checkbox
					>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col
					:xs="{ span: 24, offset: 0 }"
					:sm="{ span: 12, offset: 12 }"
					class="mt-20"
				>
					<i class="small-text">
						<!-- <span class="blue-text">&#42;</span> -->
						By clicking Process Payment, you authorize
						{{
							!merchantName === ''
								? merchantName
								: 'this merchant'
						}}
						to charge your Payment Method.
					</i>
				</el-col>
			</el-row>
			<el-row v-if="hasConvenienceFee || hasServiceFee" :gutter="15">
				<el-col
					:xs="{ span: 24, offset: 0 }"
					:sm="{ span: 12, offset: 12 }"
					class="mt-10"
				>
					<i v-if="hasConvenienceFee" class="small-text blue-text">
						Transactions are processed with a &dollar;{{
							payAdjFlat.toFixed(2)
						}}
						convenience fee for payments submitted online or over
						the phone.
					</i>
					<i v-if="hasServiceFee" class="small-text blue-text">
						Transactions are processed with a
						{{ payAdjPercent.toFixed(3) }}% service fee for all card
						payments.
					</i>
				</el-col>
			</el-row>
			<el-row :gutter="15">
				<el-col
					:xs="{ span: 24, offset: 0 }"
					:sm="{ span: 12, offset: 12 }"
					:md="{ span: 6, offset: 18 }"
					class="mt-30 mb-10"
				>
					<el-form-item class="mb-0">
						<el-button
							ref="paymentButton"
							class="payment-button"
							type="primary"
							:disabled="!amountIsValid"
							data-cy="ach-form-submit"
							@click="presubmit"
							>Process Payment</el-button
						>
					</el-form-item>
				</el-col>
			</el-row>
		</div>
	</el-form>
</template>

<script lang="ts">
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	reactive,
	ref
} from 'vue'
import { useStore } from '../store'
import Tokenizer from 'fluidpay-tokenizer'
import { ElMessageBox } from 'element-plus'
import { AchApiRequest } from '../types/simple-pay'
import * as Sentry from '@sentry/vue'
import preventExcessDigitsOnAmount from '../shared-methods/preventExcessDigitsOnAmount'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'
import { PeopleProperty } from '@/types/mixpanel'
import { Decimal } from 'decimal.js'

export default defineComponent({
	name: 'SimplePayAchForm',
	setup() {
		const store = useStore()
		const formData = reactive({
			paymentType: 'ach',
			firstName: '',
			lastName: '',
			address: '',
			address2: '',
			city: '',
			state: '',
			country: 'US',
			postalCode: '',
			dlState: '',
			dlNumber: '',
			dob: '',
			ssn: '',
			phone: '',
			email: '',
			orderId: '',
			poNumber: '',
			hideOrder: false,
			hidePO: false,
			note: '',
			shouldEmailReceipt: false,
			paymentOccurrence: 'single',
			amount: '',
			customFields: [],
			customFieldValues: {},
			vaultField: null,
			shouldVaultCustomer: false,
			company: ''
		} as any)
		const url = new URL(window.location.href) as URL
		const simplePayAchForm = ref(null as any)
		const tokenizer = ref(null as any)
		const tokenizerError = ref('')
		const hasCustomFields = ref(false)
		const hasVaultField = ref(false)
		const distinctId = ref('')
		const simplePayId = computed((): string => store.state.simplePayId)

		const username = computed(() => store.state.username)
		const bodyFontColor = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.bodyFontColor
		)

		const stateList = computed((): any => store.state.locales.stateList)
		const countryList = computed((): any => {
			return store.state.locales.countryList
		})
		const baseUrl = computed((): string => store.state.baseUrl)
		const publicApiKey = computed(
			(): string => store.state.fpMerchantData.publicApiKey
		)
		const hasDLFields = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.driversLicenseIsIncluded
		)
		const hasIDFields = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.dobSSNIsIncluded
		)
		const additionalInfoHeader = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.additionalInfoHeaderText
		)
		const hasOrderId = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.orderIdIsIncluded
		)
		const orderIdRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.orderIdIsRequired
		)
		const orderIdLabel = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.orderIdLabelText
		)
		const hasPoNumber = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.poNumberIsIncluded
		)
		const poNumberRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.poNumberIsRequired
		)
		const poNumberLabel = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.poNumberLabelText
		)
		const hasCompany = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.companyIsIncluded
		)
		const companyRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.companyIsRequired ||
				(secCode.value && secCode.value.toLowerCase() === 'ccd')
		)
		const companyLabel = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.companyLabelText
		)
		const hasNote = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.noteIsIncluded
		)
		const hasPhone = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.achFields
					.poNumberIsIncluded
		)
		const merchantName = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.merchantName
		)
		const processorId = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achProcessorId
		)
		const secCode = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achProcessing
		)
		const paymentAdjType = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.achAdjType
		)
		const paymentAdjRate = computed(
			(): number =>
				store.state.iqMerchantData.simplePaySettings.achAdjRate
		)
		const bodyFont = computed(
			(): string => store.state.iqMerchantData.simplePaySettings.bodyFont
		)
		const iqProReqFields = computed(
			(): any => store.state.fpMerchantData.iqProReqFields
		)
		const formValidationRules = computed(
			(): any => store.getters.achFormValidationRules
		)
		const postalCodeValidationRules = computed((): any => {
			// dynamically set validation rules for postal code based on the country selection
			let rules
			if (formData.country === 'US') {
				let format: RegExp
				format = /^[0-9]{5}$/

				rules = [
					{
						required: true,
						message: 'Postal code is required',
						trigger: 'blur'
					},
					{
						pattern: format,
						message: 'Must be a valid five digit postal code',
						trigger: 'blur'
					}
				]
			} else {
				rules = [
					{
						required: true,
						message: 'Postal code is required',
						trigger: 'blur'
					}
				]
			}

			return rules
		})
		const stateValidationRules = computed((): any => {
			let rules
			if (store.state.isStateless) {
				rules = [
					{
						required: true,
						message: 'State is required, please contact support',
						trigger: 'change'
					}
				]
			} else {
				rules = [
					{
						required: true,
						message: 'State is required',
						trigger: 'change'
					}
				]
			}
			return rules
		})

		const showSecurityInfo = computed((): boolean => {
			return hasDLFields.value || hasIDFields.value
		})
		const showAdditionalInfo = computed((): boolean => {
			return (
				hasOrderId.value ||
				hasPoNumber.value ||
				hasCompany.value ||
				hasPhone.value ||
				hasNote.value
			)
		})
		const amountIsValid = computed((): boolean => {
			return (
				formData.amount?.length &&
				RegExp('^[0-9]+\\.[0-9][0-9]$').test(formData.amount)
			)
		})
		const hasConvenienceFee = computed((): boolean => {
			return paymentAdjType.value === 'flat' && paymentAdjRate.value > 0
		})
		const hasServiceFee = computed((): boolean => {
			return (
				paymentAdjType.value === 'percentage' &&
				paymentAdjRate.value > 0
			)
		})
		const calculatedPercentFee = computed((): number => {
			if (!hasServiceFee.value) {
				return 0
			}

			let x = new Decimal(paymentAdjRate.value)
			let y = new Decimal(baseAmount.value)

			const rateAsDecimal = x.dividedBy(100000)
			const feeAmount = y.mul(rateAsDecimal)

			const roundedFeeAmount =
				Math.round(feeAmount.toNumber() * 100) / 100
			return roundedFeeAmount
		})
		const convenienceFeeDisplayText = computed((): string => {
			if (!hasConvenienceFee.value) {
				return '-'
			}

			return formatCurrency((paymentAdjRate.value / 100).toFixed(2))
		})
		const serviceFeeDisplayText = computed(() => {
			if (!amountIsValid.value || !hasServiceFee.value) {
				return '-'
			}

			return formatCurrency(calculatedPercentFee.value.toFixed(2))
		})
		const baseAmount = computed((): number => {
			if (amountIsValid.value === false) {
				return 0
			}
			return Number(formData.amount)
		})
		const totalAmountDisplayText = computed((): string => {
			if (!amountIsValid.value) {
				return '-'
			}

			let sumTotal = baseAmount.value
			const convFee = hasConvenienceFee.value
				? paymentAdjRate.value / 100
				: 0
			const servFee = hasServiceFee.value ? calculatedPercentFee.value : 0

			if (convFee > 0) {
				sumTotal += convFee
			} else if (servFee > 0) {
				sumTotal += servFee
			}

			return formatCurrency(sumTotal.toFixed(2))
		})
		const customFieldValuesFormatted = computed((): any => {
			const formattedObject: any = {}

			Object.keys(formData.customFieldValues).map((k) => {
				formattedObject[k] = Array.of(formData.customFieldValues[k])
			})

			return formattedObject
		})
		function formatCurrency(amount: string): string {
			return Number(amount).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'symbol'
			})
		}
		function validateDropdown() {
			simplePayAchForm.value.validateField('state')
		}
		async function presubmit() {
			mp.achPresubmitValues(formData)
			if (store.state.isStateless) {
				mp.achIsStateless(formData.country)
			}
			simplePayAchForm.value.validate((isValid: boolean) => {
				if (isValid) {
					// get values from form fields
					let amt = Number(formData.amount.replace('.', ''))

					if (amt && amt < 50000) {
						tokenizer.value.submit()
					} else {
						ElMessageBox.confirm(
							'Your account will be charged $' +
								formData.amount +
								', is this correct?',
							'Confirm Amount',
							{
								confirmButtonText: 'Confirm',
								cancelButtonText: 'Cancel',
								type: 'warning'
							}
						)
							.then(() => {
								tokenizer.value.submit()
							})
							.catch(() => {
								return
							})
					}
				}
			})
		}
		async function updateStateList() {
			formData.state = ''
			formData.postalCode = ''
			simplePayAchForm.value.clearValidate('state')
			simplePayAchForm.value.clearValidate('postalCode')

			const payload = {
				country: formData.country,
				simplePayId: simplePayId.value
			}
			try {
				await store.dispatch('getStates', payload)
				if (stateList.value.length == 0) {
					store.commit('isStateless', true)
				} else {
					store.commit('isStateless', false)
				}
			} catch (ex) {
				formData.state = ''
				console.log('location s init fail ach', ex)
			}
		}

		async function getLocations() {
			try {
				await store.dispatch('getCountries', simplePayId.value)
			} catch (ex) {
				console.log('location c init fail ach', ex)
			}
			// set initial state list to US
			const payload = {
				country: 'US',
				simplePayId: simplePayId.value
			}
			try {
				await store.dispatch('getStates', payload)
			} catch (ex) {
				console.log('location s init fail cf', ex)
			}
		}

		async function trackFormTime() {
			mp.achFormTrackTime()
		}
		async function submitForm(achToken: any) {
			try {
				store.dispatch('showLoadingScreen')

				let fname = formData.firstName.trim()
				let lname = formData.lastName.trim()
				let email = formData.email
				let address = formData.address
				let address2 = formData.address2
				let country = formData.country
				let city = formData.city
				let state = formData.state
				let postalCode = formData.postalCode
				let dlState = formData.dlState
				let dlNumber = formData.dlNumber
				let dob = formData.dob
				let ssn = formData.ssn
				let orderId = formData.orderId
				let poNumber = formData.poNumber
				let company = formData.company
				let phone = formData.phone
				let note = formData.note
				let emailReceipt = formData.shouldEmailReceipt
				let rawAmount = Number(formData.amount.replace('.', ''))
				let mixpanelAmountFormat = formData.amount

				// add always-required field values to request object
				let requestBody: AchApiRequest = {
					type: 'sale',
					amount: rawAmount,
					currency: 'USD',
					email_receipt: emailReceipt,
					group_name: 'simplepayach',
					order_id: '',
					po_number: '',
					description: '',
					default: '',
					tax_exempt: null,
					email_address: '',
					allow_partial_payment: null,
					create_vault_record: null,
					tax_amount: null,
					discount_amount: null,
					national_tax_amount: null,
					ship_from_postal_code: null,
					summary_commodity_code: '',
					processor_id: '',

					custom_fields: {},
					payment_method: {
						token: achToken
					},
					billing_address: {
						first_name: fname,
						last_name: lname,
						country: country,
						address_line_1: address,
						address_line_2: address2,
						city: city,
						state: state,
						postal_code: postalCode,
						email: email,
						company: '',
						phone: '',
						fax: ''
					},
					shipping_address: {
						first_name: '',
						last_name: '',
						company: '',
						address_line_1: '',
						address_line_2: '',
						city: '',
						state: '',
						postal_code: '',
						country: '',
						email: '',
						phone: '',
						fax: ''
					}
				}

				// add optional field values to request object if present
				if (processorId.value && processorId.value.length) {
					requestBody.processor_id = processorId.value
				}
				if (hasServiceFee.value) {
					let x = new Decimal(calculatedPercentFee.value)

					let serviceFee = x.mul(100)

					if (calculatedPercentFee.value > 0) {
						requestBody.payment_adjustment = {
							type: 'flat',
							value: serviceFee.toNumber()
						}
					}

					requestBody.amount += serviceFee.toNumber()

					mixpanelAmountFormat += serviceFee.toNumber()
				}
				if (hasConvenienceFee.value) {
					const convFee = paymentAdjRate.value

					if (calculatedPercentFee.value > 0) {
						requestBody.payment_adjustment = {
							type: 'flat',
							value: convFee
						}
					}

					requestBody.amount += convFee
					mixpanelAmountFormat += convFee
				}
				if (company && company.length) {
					requestBody.billing_address.company = company
				}
				if (emailReceipt) {
					requestBody.email_address = email
				}
				if (phone && phone.length) {
					requestBody.billing_address.phone = phone
				}
				if (dlState && dlState.length) {
					requestBody.payment_method.ach.accountholder_authentication.dl_state =
						dlState
				}
				if (dlNumber && dlNumber.length) {
					requestBody.payment_method.ach.accountholder_authentication.dl_number =
						dlNumber
				}
				if (dob && dob.length) {
					requestBody.payment_method.ach.accountholder_authentication.dob =
						dob
				}
				if (ssn && ssn.length) {
					requestBody.payment_method.ach.accountholder_authentication.ssn =
						ssn
				}
				if (orderId && orderId.length) {
					requestBody.order_id = orderId
				}
				if (poNumber && poNumber.length) {
					requestBody.po_number = poNumber
				}
				if (note && note.length) {
					requestBody.description = note
				}
				if (hasCustomFields.value) {
					requestBody.custom_fields = customFieldValuesFormatted.value
				}
				if (hasVaultField.value) {
					let formVault = formData.shouldVaultCustomer
						? ['yes']
						: ['no']
					requestBody.custom_fields[formData.vaultField.id] =
						formVault as any
					requestBody.create_vault_record =
						formData.shouldVaultCustomer
				}
				if (iqProReqFields.value.length) {
					// Can't account for different payment methods being required,
					// (payment_method_card, payment_method_ach, payment_method_terminal, payment_method_card_cvc)
					// because simplepay has to submit a token and multiple types can't be submitted together.

					// Can't set a default for Vendor ID because it is proprietary to each partner account.

					// Can't set payment adjustment because code won't allow 000 as a value
					iqProReqFields.value.forEach((element: any) => {
						switch (element) {
							case 'default':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'default'
									) ||
									!requestBody.default?.length
								) {
									requestBody.default = 'null'
								}
								break
							case 'tax_exempt':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'tax_exempt'
									) ||
									requestBody.tax_exempt == null
								) {
									requestBody.tax_exempt = false
								}
								break
							case 'description':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'description'
									) ||
									!requestBody.description?.length
								) {
									requestBody.description = 'null'
								}
								break
							case 'order_id':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'order_id'
									) ||
									!requestBody.order_id?.length
								) {
									requestBody.order_id = 'null'
								}
								break
							case 'po_number':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'po_number'
									) ||
									!requestBody.po_number?.length
								) {
									requestBody.po_number = 'null'
								}
								break
							case 'email_address':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'email_address'
									) ||
									!requestBody.email_address?.length
								) {
									requestBody.email_address =
										'no_email_provided@null.com'
								}
								break
							case 'processor_id':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'processor_id'
									) ||
									!requestBody.processor_id?.length
								) {
									requestBody.processor_id = 'null'
								}
								break
							case 'allow_partial_payment':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'allow_partial_payment'
									) ||
									requestBody.allow_partial_payment == null
								) {
									requestBody.allow_partial_payment = false
								}
								break
							case 'create_vault_record':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'create_vault_record'
									) ||
									requestBody.create_vault_record == null
								) {
									requestBody.create_vault_record = false
								}
								break
							case 'tax_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'tax_amount'
									) ||
									!requestBody.tax_amount
								) {
									requestBody.tax_amount = 0
								}
								break
							case 'shipping_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_amount'
									) ||
									!requestBody.shipping_amount
								) {
									requestBody.shipping_amount = 0
								}
								break
							case 'discount_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'discount_amount'
									) ||
									!requestBody.discount_amount
								) {
									requestBody.discount_amount = 0
								}
								break
							case 'billing_first_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'first_name'
									) ||
									!requestBody.billing_address.first_name
										?.length
								) {
									requestBody.billing_address.first_name =
										'null'
								}
								break
							case 'billing_last_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'last_name'
									) ||
									!requestBody.billing_address.last_name
										?.length
								) {
									requestBody.billing_address.last_name =
										'null'
								}
								break
							case 'billing_company':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'company'
									) ||
									!requestBody.billing_address.company?.length
								) {
									requestBody.billing_address.company = 'null'
								}
								break
							case 'billing_address_line_1':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'address_line_1'
									) ||
									!requestBody.billing_address.address_line_1
										?.length
								) {
									requestBody.billing_address.address_line_1 =
										'null'
								}
								break
							case 'billing_address_line_2':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'address_line_2'
									) ||
									!requestBody.billing_address.address_line_2
										?.length
								) {
									requestBody.billing_address.address_line_2 =
										'null'
								}
								break
							case 'billing_city':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'city'
									) ||
									!requestBody.billing_address.city?.length
								) {
									requestBody.billing_address.city = 'null'
								}
								break
							case 'billing_state':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'state'
									) ||
									!requestBody.billing_address.state?.length
								) {
									requestBody.billing_address.state = 'na'
								}
								break
							case 'billing_postal_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'postal_code'
									) ||
									!requestBody.billing_address?.postal_code
										.length
								) {
									requestBody.billing_address.postal_code =
										'00000'
								}
								break
							case 'billing_country':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'country'
									) ||
									!requestBody.billing_address.country?.length
								) {
									requestBody.billing_address.country = 'US'
								}
								break
							case 'billing_email':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'email'
									) ||
									!requestBody.billing_address.email?.length
								) {
									requestBody.billing_address.email =
										'no_email_provided@null.com'
								}
								break
							case 'billing_phone':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'phone'
									) ||
									!requestBody.billing_address.phone?.length
								) {
									requestBody.billing_address.phone =
										'0000000000'
								}
								break
							case 'billing_fax':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'fax'
									) ||
									!requestBody.billing_address.fax?.length
								) {
									requestBody.billing_address.fax =
										'0000000000'
								}
								break
							case 'shipping_first_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'first_name'
									) ||
									!requestBody.shipping_address?.first_name
										?.length
								) {
									requestBody.shipping_address.first_name =
										'null'
								}
								break
							case 'shipping_last_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'last_name'
									) ||
									(!requestBody.shipping_address?.last_name
										?.length &&
										requestBody.shipping_address
											.last_name !== undefined)
								) {
									requestBody.shipping_address.last_name = ''
								}
								break
							case 'shipping_company':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'company'
									) ||
									!requestBody.shipping_address?.company
										?.length
								) {
									requestBody.shipping_address.company =
										'null'
								}
								break
							case 'shipping_address_line_1':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'address_line_1'
									) ||
									!requestBody.shipping_address
										?.address_line_1?.length
								) {
									requestBody.shipping_address.address_line_1 =
										'null'
								}
								break
							case 'shipping_address_line_2':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'address_line_2'
									) ||
									!requestBody.shipping_address
										?.address_line_2?.length
								) {
									requestBody.shipping_address.address_line_2 =
										'null'
								}
								break
							case 'shipping_city':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'city'
									) ||
									!requestBody.shipping_address?.city?.length
								) {
									requestBody.shipping_address.city = 'null'
								}
								break
							case 'shipping_state':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'state'
									) ||
									!requestBody.shipping_address?.state?.length
								) {
									requestBody.shipping_address.state = 'na'
								}
								break
							case 'shipping_postal_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'postal_code'
									) ||
									!requestBody.shipping_address?.postal_code
										?.length
								) {
									requestBody.shipping_address.postal_code =
										'00000'
								}
								break
							case 'shipping_country':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'country'
									) ||
									!requestBody.shipping_address?.country
										?.length
								) {
									requestBody.shipping_address.country = 'US'
								}
								break
							case 'shipping_email':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'email'
									) ||
									!requestBody.shipping_address?.email?.length
								) {
									requestBody.shipping_address.email =
										'no_email_provided@null.com'
								}
								break
							case 'shipping_phone':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'phone'
									) ||
									!requestBody.shipping_address?.phone?.length
								) {
									requestBody.shipping_address.phone =
										'0000000000'
								}
								break
							case 'shipping_fax':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'fax'
									) ||
									!requestBody.shipping_address?.fax?.length
								) {
									requestBody.shipping_address.fax =
										'0000000000'
								}
								break
							case 'national_tax_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'national_tax_amount'
									) ||
									!requestBody.national_tax_amount
								) {
									requestBody.national_tax_amount = 0
								}
								break
							case 'duty_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'duty_amount'
									) ||
									!requestBody.duty_amount
								) {
									requestBody.duty_amount = 0
								}
								break
							case 'ship_from_postal_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'ship_from_postal_code'
									) ||
									!requestBody?.ship_from_postal_code?.length
								) {
									requestBody.ship_from_postal_code = '00000'
								}
								break
							case 'summary_commodity_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'summary_commodity_code'
									) ||
									!requestBody.summary_commodity_code?.length
								) {
									requestBody.summary_commodity_code = 'null'
								}
								break
							case 'merchant_vat_registration_number':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'merchant_vat_registration_number'
									) ||
									!requestBody
										.merchant_vat_registration_number
										?.length
								) {
									requestBody.merchant_vat_registration_number =
										'null'
								}
								break
							case 'customer_vat_registration_number':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'customer_vat_registration_number'
									) ||
									!requestBody
										.customer_vat_registration_number
										?.length
								) {
									requestBody.customer_vat_registration_number =
										'null'
								}
								break
							default:
								break
						}
					})
				}
				if (formData.name) {
					// only set the name to the form name if they are not an admin user
					if (username.value) {
						const userData: PeopleProperty = {
							email: formData.email,
							name: username.value,
							amount: mixpanelAmountFormat
						}
						mp.achFormSetPeopleProp(userData)
					} else {
						const userData: PeopleProperty = {
							email: formData.email,
							name: formData.first_name,
							amount: mixpanelAmountFormat
						}
						mp.achFormSetPeopleProp(userData)
					}
				}

				store.dispatch('submitTransaction', {
					requestBody: requestBody,
					grecaptchaAction: 'simplePayAchSubmit'
				})
			} catch (ex) {
				Sentry.captureException(ex)
				Sentry.captureMessage('SimplyPay ACH Submit - Unknown Error')
				store.dispatch('hideLoadingScreen')
			}
		}
		async function getCustomFields() {
			try {
				const customFields = store.state.fpMerchantData.achCustomFields
				if (customFields && customFields.length > 0) {
					hasCustomFields.value = true
					formData.customFields = customFields
				}
				const vaultField =
					store.state.fpMerchantData.vaultCustomFieldAch
				if (vaultField && vaultField.length > 0) {
					hasVaultField.value = true
					formData.vaultField = vaultField[0]
					formData.shouldVaultCustomer = true
					simplePayAchForm.value.clearValidate()
				}
			} catch (ex) {
				Sentry.captureException(ex)
				Sentry.captureMessage('gcf attempt failed')
				console.log('gcf attempt failed', ex)
			}
		}
		function loadQueryParams() {
			const params = new URLSearchParams(url.search)

			if (params.has('firstName')) {
				formData.firstName = params.get('firstName')
			}
			if (params.has('lastName')) {
				formData.lastName = params.get('lastName')
			}
			if (params.has('company')) {
				formData.company = params.get('company')
			}
			if (params.has('state')) {
				const state = params.get('state')?.toUpperCase()
				if (!store.state.isStateless) {
					formData.state = state
				}
			}
			if (params.has('address')) {
				formData.address = params.get('address')
			}
			if (params.has('address2')) {
				formData.address2 = params.get('address2')
			}
			if (params.has('country')) {
				formData.country = params.get('country')
			}
			if (params.has('city')) {
				formData.city = params.get('city')
			}
			if (params.has('postalCode')) {
				formData.postalCode = params.get('postalCode')
			}
			if (params.has('email')) {
				formData.email = params.get('email')
			}
			if (params.has('phone')) {
				formData.phone = params.get('phone')
			}
			if (params.has('orderId')) {
				formData.orderId = params.get('orderId')
			}
			if (params.has('hideOrder')) {
				formData.hideOrder = params.get('hideOrder')
			}
			if (params.has('poNumber')) {
				formData.poNumber = params.get('poNumber')
			}
			if (params.has('hidePO')) {
				formData.hidePO = params.get('hidePO')
			}
			if (params.has('note')) {
				formData.note = params.get('note')
			}

			if (params.has('amount')) {
				const amountParam = params.get('amount')
				// scrub any non-digits out of the amount
				const scrubbedAmount = amountParam.replace(/\D/g, '')
				// format
				const decimalPosition = scrubbedAmount.length - 2
				const dollars = scrubbedAmount.slice(0, decimalPosition)
				const cents = scrubbedAmount.slice(decimalPosition)
				const formattedAmount = dollars + '.' + cents

				formData.amount = formattedAmount
			}
			// run field validation on the inputs with pre-filled values
			params.forEach((value, key) => {
				if (key !== 'id') {
					simplePayAchForm.value.validateField(key)
				}
			})
		}

		onMounted(() => {
			simplePayAchForm.value.clearValidate()
			getCustomFields()
			loadQueryParams()
			getLocations()

			// get global var available through vue app
			const app = getCurrentInstance()
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)

			tokenizer.value = new Tokenizer({
				apikey: publicApiKey.value,
				url: baseUrl.value,
				container: document.querySelector('#ach-container'),
				settings: {
					payment: {
						types: ['ach'],
						ach: {
							sec_code: secCode.value
						},
						showTitle: false
					},
					styles: {
						'.ach>div:not(:last-child):not(.break)': {
							padding: '0 15px 0 0'
						},
						'.ach .fieldset': {
							'margin-bottom': '14px',
							position: 'relative'
						},
						'.ach .fieldset::before': {
							'line-height': '1em',
							padding: '0 0 7px 1px',
							display: 'inline-block',
							'font-size': '13px',
							color: bodyFontColor.value
						},
						'.ach .type::before': {
							content: '"Acct Type"',
							'font-family': bodyFont.value
						},
						'.ach .type select': {
							padding: '5px 5px 5px 10px',
							color: bodyFontColor.value,
							border: `solid 1px` + bodyFontColor.value
						},
						'.ach .account::before': {
							content: '"Account Number"',
							'font-family': bodyFont.value,
							color: bodyFontColor.value
						},
						'ach input': {
							color: bodyFontColor.value
						},
						'.ach .routing::before': {
							content: '"Routing Number"',
							'font-family': bodyFont.value
						},
						'.ach .account input::placeholder,.ach .routing input::placeholder':
							{
								color: bodyFontColor.value,
								opacity: '.5'
							},
						'.ach .account input': {
							border: `solid 1px` + bodyFontColor.value
						},
						input: {
							border: `solid 1px` + bodyFontColor.value
						}
					}
				},
				onLoad: () => {
					store.dispatch('hideLoadingScreen')
				},
				submission: (resp) => {
					switch (resp.status) {
						case 'success':
							// Successful submission
							if (resp.token) {
								submitForm(resp.token)
							}
							break
						case 'error':
							// Encountered an error while performing submission
							Sentry.withScope(function (scope) {
								scope.setTag('simplepayid page', url.href)

								Sentry.captureMessage(
									'Tokenizer Error: Encountered an error while performing ach submission'
								)
							})

							tokenizerError.value = resp.message
							window.scrollTo(0, 0)
							break
						case 'validation':
							// Encountered form validation specific errors
							tokenizerError.value =
								'Invalid account information. Please verify and try again.'
							window.scrollTo(0, 0)
							break
					}
				}
			})
		})

		return {
			formData,
			simplePayAchForm,
			hasVaultField,
			tokenizerError,
			stateList,
			countryList,
			hasDLFields,
			hasIDFields,
			additionalInfoHeader,
			hasOrderId,
			orderIdRequired,
			hasPoNumber,
			poNumberRequired,
			poNumberLabel,
			companyRequired,
			companyLabel,
			hasNote,
			hasPhone,
			merchantName,
			formValidationRules,
			stateValidationRules,
			validateDropdown,
			showSecurityInfo,
			showAdditionalInfo,
			amountIsValid,
			hasConvenienceFee,
			hasServiceFee,
			convenienceFeeDisplayText,
			serviceFeeDisplayText,
			totalAmountDisplayText,
			postalCodeValidationRules,
			presubmit,
			hasCompany,
			orderIdLabel,
			updateStateList,
			preventExcessDigitsOnAmount,
			trackFormTime
		}
	}
})
</script>

<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
	width: 100%;
}
</style>

<style lang="scss" scoped>
:deep(#ach-container *) {
	transition: all 0s ease-out 0s !important;
}
</style>
