<template>
	<el-header
		:style="{
			'--header-color': headerColor,
			'--header-font-color': headerFontColor
		}"
		:class="{ 'logo-enlarge': enlargeLogo }"
	>
		<div class="header-container">
			<div class="logo-wrapper">
				<!-- display if merchant provided a logo -->
				<img
					v-if="merchantLogo && formIsActive"
					:src="merchantLogo"
					:alt="merchantName + ' logo'"
				/>
				<!-- if no merchant logo fall back to merchant name -->
				<h1 v-if="!merchantLogo && merchantName && formIsActive">
					{{ merchantName }}
				</h1>
				<!-- display if no merchant logo or merchant name -->
				<img
					v-if="(!merchantLogo && !merchantName) || !formIsActive"
					:src="iqProLogo"
					alt="IQ Pro logo"
				/>
			</div>
			<div v-if="headerText && headerText.length" class="header-text">
				<span>{{ headerText }}</span>
			</div>
		</div>
	</el-header>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useStore } from '../../store'

export default defineComponent({
	name: 'SimplePayHeader',
	props: {
		headerColor: {
			type: String,
			default: '#fff'
		},
		headerFontColor: {
			type: String,
			default: '#222'
		}
	},
	setup() {
		const store = useStore()
		const publicPath = ref(process.env.BASE_URL)
		const iqProLogo = computed((): string => {
			return store.state.logos.iqProLogoNavy
		})
		const merchantLogo = computed((): string => {
			let merchantLogo = store.state.iqMerchantData.simplePaySettings.logo

			return merchantLogo ?? ''
		})
		const enlargeLogo = computed((): boolean => {
			return store.state.iqMerchantData.simplePaySettings.enlargeLogo
		})
		const merchantName = computed((): string => {
			let merchantName =
				store.state.iqMerchantData.simplePaySettings.merchantName

			return merchantName ?? ''
		})
		const headerText = computed((): string => {
			let headerText =
				store.state.iqMerchantData.simplePaySettings.headerText

			return headerText ?? ''
		})
		const formIsActive = computed((): boolean => {
			return store.state.iqMerchantData.simplePaySettings.isActive
		})

		return {
			publicPath,
			iqProLogo,
			merchantLogo,
			enlargeLogo,
			merchantName,
			headerText,
			formIsActive
		}
	}
})
</script>

<style lang="scss" scoped>
header {
	background: var(--header-color);
	width: 100vw;
	height: auto !important;
	box-shadow: 0px -3px 6px 3px $shadow-gray;

	.header-container {
		max-width: 800px;
		margin: auto;
		display: flex;
		align-items: center;
	}

	.logo-wrapper {
		order: 1;

		img {
			height: 36px;
			margin: 10px 0;
		}

		h1 {
			color: var(--header-font-color);
			display: inline-block;
			white-space: nowrap;
			margin: 0;
			font-size: 16px;
			line-height: 56px;
			letter-spacing: 0.5px;
		}
	}

	.header-text {
		order: 2;
		flex-grow: 1;
		color: var(--header-font-color);
		padding: 1em 0 1em 1.5em;
		text-align: right;
	}

	&.logo-enlarge {
		.logo-wrapper img {
			height: 72px;
		}

		h1 {
			line-height: 92px;
		}
	}
}

@media screen and (max-width: 620px) {
	header {
		height: auto !important;
		text-align: center;

		.header-container {
			flex-flow: column wrap;
		}

		.logo-wrapper h1 {
			font-size: 14px;
			line-height: calc(1em + 40px);
		}

		.header-text {
			padding: 0 0 15px;
			text-align: center;
		}
	}
}
</style>
