
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	reactive,
	ref
} from 'vue'
import { useStore } from '../store'
import Tokenizer from 'fluidpay-tokenizer'
import { ElMessageBox } from 'element-plus'
import { CardApiRequest } from '../types/simple-pay'
import * as Sentry from '@sentry/vue'
import preventExcessDigitsOnAmount from '../shared-methods/preventExcessDigitsOnAmount'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'
import { PeopleProperty } from '@/types/mixpanel'
import { Decimal } from 'decimal.js'

export default defineComponent({
	name: 'SimplePayCardForm',
	setup() {
		const store = useStore()
		const formData = reactive({
			paymentType: 'card',
			firstName: '',
			lastName: '',
			company: '',
			country: 'US',
			address: '',
			address2: '',
			city: '',
			state: '',
			postalCode: '',
			email: '',
			phone: '',
			orderId: '',
			hideOrder: false,
			hidePO: false,
			poNumber: '',
			note: '',
			shouldEmailReceipt: false,
			amount: '',
			customFields: [],
			customFieldValues: {},
			vaultField: null,
			shouldVaultCustomer: false
		} as any)
		const url = new URL(window.location.href) as URL
		const simplePayCardForm = ref(null)
		const tokenizer = ref(null)
		const tokenizerError = ref('')
		const hasCustomFields = ref(false)
		const hasVaultField = ref(false)
		const distinctId = ref('')
		const simplePayId = computed((): string => store.state.simplePayId)

		const username = computed(() => store.state.username)
		const cardBin = ref(null)
		const bodyFontColor = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.bodyFontColor
		)

		const isCardholderSurchargeable = computed(
			(): any => store.state.isCardholderSurchargeable
		)
		const stateList = computed((): any => store.state.locales.stateList)
		const countryList = computed((): any => {
			return store.state.locales.countryList
		})
		const baseUrl = computed((): string => store.state.baseUrl)
		const publicApiKey = computed(
			(): string => store.state.fpMerchantData.publicApiKey
		)
		const additionalInfoHeader = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.additionalInfoHeaderText
		)
		const hasCompany = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.companyIsIncluded
		)
		const companyRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.companyIsRequired
		)
		const companyLabel = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.companyLabelText
		)
		const hasAddress = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.addressIsIncluded
		)
		const addressRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.addressIsRequired
		)
		const hasEmail = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.emailIsIncluded
		)
		const emailRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.emailIsRequired
		)
		const hasPhone = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.phoneIsIncluded
		)
		const phoneRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.phoneIsRequired
		)
		const hasOrderId = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.orderIdIsIncluded
		)
		const orderIdRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.orderIdIsRequired
		)
		const orderIdLabel = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.orderIdLabelText
		)
		const hasPoNumber = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.poNumberIsIncluded
		)
		const poNumberRequired = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.poNumberIsRequired
		)
		const poNumberLabel = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.poNumberLabelText
		)
		const hasNote = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings.cardFields
					.noteIsIncluded
		)
		const merchantName = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.merchantName
		)
		const processorId = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.cardProcessorId
		)
		const isSurchargingEnabled = computed(
			(): boolean =>
				store.state.iqMerchantData.simplePaySettings
					.isSurchargingEnabled
		)
		const paymentAdjType = computed(
			(): string =>
				store.state.iqMerchantData.simplePaySettings.cardAdjType
		)
		const paymentAdjRate = computed(
			(): number =>
				store.state.iqMerchantData.simplePaySettings.cardAdjRate
		)
		const bodyFont = computed(
			() => store.state.iqMerchantData.simplePaySettings.bodyFont
		)
		const iqProReqFields = computed(
			() => store.state.fpMerchantData.iqProReqFields
		)
		const formValidationRules = computed(
			(): any => store.getters.cardFormValidationRules
		)
		const postalCodeValidationRules = computed((): any => {
			let rules
			if (formData.country === 'US') {
				let format: RegExp
				format = /^[0-9]{5}$/

				rules = [
					{
						required:
							store.state.iqMerchantData.simplePaySettings
								.cardFields.addressIsRequired,
						message: 'Postal code is required',
						trigger: 'blur'
					},
					{
						pattern: format,
						message: 'Must be a valid five digit postal code',
						trigger: 'blur'
					}
				]
			} else {
				rules = [
					{
						required:
							store.state.iqMerchantData.simplePaySettings
								.cardFields.addressIsRequired,
						message: 'Postal code is required',
						trigger: 'blur'
					}
				]
			}

			return rules
		})
		const stateValidationRules = computed((): any => {
			let rules
			if (store.state.isStateless) {
				rules = [
					{
						required:
							store.state.iqMerchantData.simplePaySettings
								.cardFields.addressIsRequired,
						message: 'State is required, please contact support',
						trigger: 'change'
					}
				]
			} else {
				rules = [
					{
						required:
							store.state.iqMerchantData.simplePaySettings
								.cardFields.addressIsRequired,
						message: 'State is required',
						trigger: 'change'
					}
				]
			}
			return rules
		})

		const showAdditionalInfo = computed((): boolean => {
			return (
				hasEmail.value ||
				hasPhone.value ||
				hasOrderId.value ||
				hasPoNumber.value ||
				hasNote.value
			)
		})
		const isEmailRequired = computed((): any => {
			return (
				formData.shouldEmailReceipt ||
				formData.shouldVaultCustomer ||
				emailRequired.value
			)
		})
		const isPhoneRequired = computed((): boolean => {
			return formData.shouldVaultCustomer || phoneRequired.value
		})
		const amountIsValid = computed((): boolean => {
			return (
				formData.amount?.length &&
				RegExp('^[0-9]+\\.[0-9][0-9]$').test(formData.amount)
			)
		})
		const hasSurcharge = computed((): boolean => {
			return (
				isSurchargingEnabled.value === true &&
				paymentAdjType.value === 'percentage' &&
				paymentAdjRate.value > 0
			)
		})
		const hasConvenienceFee = computed((): boolean => {
			return paymentAdjType.value === 'flat' && paymentAdjRate.value > 0
		})
		const hasServiceFee = computed((): boolean => {
			return (
				isSurchargingEnabled.value === false &&
				paymentAdjType.value === 'percentage' &&
				paymentAdjRate.value > 0
			)
		})
		const calculatedPercentFee = computed((): number => {
			if (!hasSurcharge.value && !hasServiceFee.value) {
				return 0
			}

			let x = new Decimal(paymentAdjRate.value)
			let y = new Decimal(baseAmount.value)

			const rateAsDecimal = x.dividedBy(100000)
			const feeAmount = y.mul(rateAsDecimal)

			const roundedFeeAmount =
				Math.round(feeAmount.toNumber() * 100) / 100
			return roundedFeeAmount
		})

		const convenienceFeeDisplayText = computed((): string => {
			if (!hasConvenienceFee.value) {
				return '-'
			}

			return formatCurrency((paymentAdjRate.value / 100).toFixed(2))
		})
		const serviceFeeDisplayText = computed((): string => {
			if (!amountIsValid.value || !hasServiceFee.value) {
				return '-'
			}

			return formatCurrency(calculatedPercentFee.value.toFixed(2))
		})
		const percentAdjRateDisplayText = computed((): string => {
			return (paymentAdjRate.value / 1000).toString()
		})
		const baseAmount = computed((): number => {
			if (amountIsValid.value === false) {
				return 0
			}

			return Number(formData.amount)
		})
		const totalAmountDisplayText = computed((): string => {
			if (!amountIsValid.value) {
				return '-'
			}

			let sumTotal = baseAmount.value
			const convFee = hasConvenienceFee.value
				? paymentAdjRate.value / 100
				: 0
			const servFee = hasServiceFee.value ? calculatedPercentFee.value : 0

			if (convFee > 0) {
				sumTotal += convFee
			} else if (servFee > 0) {
				sumTotal += servFee
			}

			return formatCurrency(sumTotal.toFixed(2))
		})
		const customFieldValuesFormatted = computed((): any => {
			const formattedObject: any = {}

			Object.keys(formData.customFieldValues).map((k) => {
				formattedObject[k] = Array.of(formData.customFieldValues[k])
			})

			return formattedObject
		})

		async function updateStateList() {
			formData.state = ''
			formData.postalCode = ''
			simplePayCardForm.value.clearValidate('state')
			simplePayCardForm.value.clearValidate('postalCode')
			const payload = {
				country: formData.country,
				simplePayId: simplePayId.value
			}

			try {
				await store.dispatch('getStates', payload)
				if (stateList.value.length == 0) {
					store.commit('isStateless', true)
				} else {
					store.commit('isStateless', false)
				}
			} catch (ex) {
				console.log('location s init fail cf', ex)
			}
		}
		async function updateTokenizerState() {
			tokenizer.value.validCard(null)
		}
		async function getLocations() {
			try {
				await store.dispatch('getCountries', simplePayId.value)
			} catch (ex) {
				console.log('location c init fail cf', ex)
			}
			const payload = {
				country: 'US',
				simplePayId: simplePayId.value
			}
			// set initial state list to US
			try {
				await store.dispatch('getStates', payload)
			} catch (ex) {
				console.log('location s init fail cf', ex)
			}
		}
		function formatCurrency(amount: string) {
			return Number(amount).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'symbol'
			})
		}

		async function presubmit() {
			mp.cardPresubmitValues(formData)
			if (store.state.isStateless) {
				mp.cardIsStateless(formData.country)
			}

			simplePayCardForm.value.validate((isValid: any) => {
				if (isValid) {
					// get values from form fields
					let amt = Number(formData.amount.replace('.', ''))
					// if amount is greater than 500 dollars, pop a confirmation
					if (amt && amt < 50000) {
						tokenizer.value.submit()
					} else {
						ElMessageBox.confirm(
							'Your account will be charged $' +
								formData.amount +
								', is this correct?',
							'Confirm Amount',
							{
								confirmButtonText: 'Confirm',
								cancelButtonText: 'Cancel',
								type: 'warning'
							}
						)
							.then(() => {
								tokenizer.value.submit()
							})
							.catch(() => {
								return
							})
					}
				}
			})
		}
		async function trackFormTime() {
			mp.cardFormTrackTime()
		}

		async function submitForm(cardToken: any) {
			try {
				store.dispatch('showLoadingScreen')

				let fname = formData.firstName.trim()
				let lname = formData.lastName.trim()
				let company = formData.company
				let country = formData.country
				let address = formData.address
				let address2 = formData.address2
				let city = formData.city
				let state = formData.state
				let postalCode = formData.postalCode
				let email = formData.email
				let phone = formData.phone
				let orderId = formData.orderId
				let poNumber = formData.poNumber
				let note = formData.note
				let emailReceipt = formData.shouldEmailReceipt
				let rawAmount = Number(formData.amount.replace('.', ''))
				let mixpanelAmountFormat = formData.amount

				// add always-required field values to request object
				let requestBody: CardApiRequest = {
					type: 'sale',
					amount: rawAmount,
					currency: 'USD',
					email_receipt: emailReceipt,
					group_name: 'simplepaycard',
					custom_fields: {},
					processor_id: '',
					national_tax_amount: null,
					ship_from_postal_code: null,
					summary_commodity_code: '',
					email_address: '',
					order_id: '',
					po_number: '',
					description: '',
					default: '',
					tax_exempt: null,
					allow_partial_payment: null,
					create_vault_record: null,
					tax_amount: null,
					shipping_amount: null,
					discount_amount: null,
					payment_method: {
						token: cardToken
					},
					billing_address: {
						first_name: fname,
						last_name: lname,
						country: country,
						company: '',
						address_line_1: '',
						address_line_2: '',
						city: '',
						state: '',
						postal_code: '',
						email: '',
						phone: '',
						fax: ''
					},
					shipping_address: {
						first_name: '',
						last_name: '',
						company: '',
						address_line_1: '',
						address_line_2: '',
						city: '',
						state: '',
						postal_code: '',
						country: '',
						email: '',
						phone: '',
						fax: ''
					}
				}

				// add optional field values to request object if present
				if (hasSurcharge.value && isCardholderSurchargeable.value) {
					let x = new Decimal(calculatedPercentFee.value)
					const surcharge = x.mul(100)
					requestBody.surcharge = surcharge.toNumber()
					requestBody.amount += surcharge.toNumber()
					mixpanelAmountFormat += surcharge.toNumber()
				}

				if (hasServiceFee.value) {
					let x = new Decimal(calculatedPercentFee.value)
					let serviceFee = x.mul(100)

					if (calculatedPercentFee.value > 0) {
						requestBody.payment_adjustment = {
							type: 'flat',
							value: serviceFee.toNumber()
						}
					}
					requestBody.amount += serviceFee.toNumber()

					mixpanelAmountFormat += serviceFee.toNumber()
				}
				if (hasConvenienceFee.value) {
					const convFee = paymentAdjRate.value

					if (calculatedPercentFee.value > 0) {
						requestBody.payment_adjustment = {
							type: 'flat',
							value: convFee
						}
					}
					requestBody.amount += convFee
				}
				requestBody.processor_id = processorId.value ?? ''
				requestBody.billing_address.company = company ?? ''
				requestBody.billing_address.address_line_1 = address ?? ''
				requestBody.billing_address.address_line_2 = address2 ?? ''
				requestBody.billing_address.city = city ?? ''
				requestBody.billing_address.state = state ?? ''
				requestBody.billing_address.postal_code = postalCode ?? ''
				requestBody.billing_address.email = email ?? ''
				requestBody.email_address = emailReceipt ? email : ''
				requestBody.billing_address.phone = phone ?? ''
				requestBody.order_id = orderId ?? ''
				requestBody.po_number = poNumber ?? ''
				requestBody.description = note ?? ''

				if (hasCustomFields.value) {
					requestBody.custom_fields = customFieldValuesFormatted.value
				}
				if (hasVaultField.value) {
					let formVault = formData.shouldVaultCustomer
						? ['yes']
						: ['no']
					requestBody.custom_fields[formData.vaultField.id] =
						formVault as any
					requestBody.create_vault_record =
						formData.shouldVaultCustomer
				}
				if (iqProReqFields.value.length) {
					// Can't account for different payment methods being required,
					// (payment_method_card, payment_method_ach, payment_method_terminal, payment_method_card_cvc)
					// because simplepay has to submit a token and multiple types can't be submitted together.

					// Can't set a default for Vendor ID because it is proprietary to each partner account.

					// Can't set payment adjustment because code won't allow 000 as a value
					iqProReqFields.value.forEach((element: any) => {
						switch (element) {
							case 'default':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'default'
									) ||
									!requestBody.default.length
								) {
									requestBody.default = 'null'
								}
								break
							case 'tax_exempt':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'tax_exempt'
									) ||
									requestBody.tax_exempt == null
								) {
									requestBody.tax_exempt = false
								}
								break
							case 'description':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'description'
									) ||
									!requestBody.description.length
								) {
									requestBody.description = 'null'
								}
								break
							case 'order_id':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'order_id'
									) ||
									!requestBody.order_id.length
								) {
									requestBody.order_id = 'null'
								}
								break
							case 'po_number':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'po_number'
									) ||
									!requestBody.po_number.length
								) {
									requestBody.po_number = 'null'
								}
								break
							case 'email_address':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'email_address'
									) ||
									!requestBody.email_address.length
								) {
									requestBody.email_address =
										'no_email_provided@null.com'
								}
								break
							case 'processor_id':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'processor_id'
									) ||
									!requestBody.processor_id.length
								) {
									requestBody.processor_id = 'null'
								}
								break
							case 'allow_partial_payment':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'allow_partial_payment'
									) ||
									requestBody.allow_partial_payment == null
								) {
									requestBody.allow_partial_payment = false
								}
								break
							case 'create_vault_record':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'create_vault_record'
									) ||
									requestBody.create_vault_record == null
								) {
									requestBody.create_vault_record = false
								}
								break
							case 'tax_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'tax_amount'
									) ||
									!requestBody.tax_amount
								) {
									requestBody.tax_amount = 0
								}
								break
							case 'shipping_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_amount'
									) ||
									!requestBody.shipping_amount
								) {
									requestBody.shipping_amount = 0
								}
								break
							case 'discount_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'discount_amount'
									) ||
									!requestBody.discount_amount
								) {
									requestBody.discount_amount = 0
								}
								break
							case 'billing_first_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'first_name'
									) ||
									!requestBody.billing_address.first_name
										?.length
								) {
									requestBody.billing_address.first_name =
										'null'
								}
								break
							case 'billing_last_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'last_name'
									) ||
									!requestBody.billing_address.last_name
										?.length
								) {
									requestBody.billing_address.last_name =
										'null'
								}
								break
							case 'billing_company':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'company'
									) ||
									!requestBody.billing_address.company?.length
								) {
									requestBody.billing_address.company = 'null'
								}
								break
							case 'billing_address_line_1':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'address_line_1'
									) ||
									!requestBody.billing_address.address_line_1
										?.length
								) {
									requestBody.billing_address.address_line_1 =
										'null'
								}
								break
							case 'billing_address_line_2':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'address_line_2'
									) ||
									!requestBody.billing_address.address_line_2
										?.length
								) {
									requestBody.billing_address.address_line_2 =
										'null'
								}
								break
							case 'billing_city':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'city'
									) ||
									!requestBody.billing_address.city?.length
								) {
									requestBody.billing_address.city = 'null'
								}
								break
							case 'billing_state':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'state'
									) ||
									!requestBody.billing_address.state?.length
								) {
									requestBody.billing_address.state = 'na'
								}
								break
							case 'billing_postal_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'postal_code'
									) ||
									!requestBody.billing_address.postal_code
										?.length
								) {
									requestBody.billing_address.postal_code =
										'00000'
								}
								break
							case 'billing_country':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'country'
									) ||
									!requestBody.billing_address.country?.length
								) {
									requestBody.billing_address.country = 'US'
								}
								break
							case 'billing_email':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'email'
									) ||
									!requestBody.billing_address.email?.length
								) {
									requestBody.billing_address.email =
										'no_email_provided@null.com'
								}
								break
							case 'billing_phone':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'phone'
									) ||
									!requestBody.billing_address.phone?.length
								) {
									requestBody.billing_address.phone =
										'0000000000'
								}
								break
							case 'billing_fax':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'billing_address'
									)
								) {
									requestBody.billing_address = {} as any
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.billing_address,
										'fax'
									) ||
									!requestBody.billing_address.fax?.length
								) {
									requestBody.billing_address.fax =
										'0000000000'
								}
								break
							case 'shipping_first_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'first_name'
									) ||
									!requestBody.shipping_address.first_name
										?.length
								) {
									requestBody.shipping_address.first_name =
										'null'
								}
								break
							case 'shipping_last_name':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'last_name'
									) ||
									!requestBody.shipping_address.last_name
										?.length
								) {
									requestBody.shipping_address.last_name =
										'null'
								}
								break
							case 'shipping_company':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'company'
									) ||
									!requestBody.shipping_address.company
										?.length
								) {
									requestBody.shipping_address.company =
										'null'
								}
								break
							case 'shipping_address_line_1':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'address_line_1'
									) ||
									!requestBody.shipping_address.address_line_1
										?.length
								) {
									requestBody.shipping_address.address_line_1 =
										'null'
								}
								break
							case 'shipping_address_line_2':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'address_line_2'
									) ||
									!requestBody.shipping_address.address_line_2
										?.length
								) {
									requestBody.shipping_address.address_line_2 =
										'null'
								}
								break
							case 'shipping_city':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'city'
									) ||
									!requestBody.shipping_address.city?.length
								) {
									requestBody.shipping_address.city = 'null'
								}
								break
							case 'shipping_state':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'state'
									) ||
									!requestBody.shipping_address.state?.length
								) {
									requestBody.shipping_address.state = 'na'
								}
								break
							case 'shipping_postal_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'postal_code'
									) ||
									!requestBody.shipping_address.postal_code
										?.length
								) {
									requestBody.shipping_address.postal_code =
										'00000'
								}
								break
							case 'shipping_country':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'country'
									) ||
									!requestBody.shipping_address.country
										?.length
								) {
									requestBody.shipping_address.country = 'US'
								}
								break
							case 'shipping_email':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'email'
									) ||
									!requestBody.shipping_address.email?.length
								) {
									requestBody.shipping_address.email =
										'no_email_provided@null.com'
								}
								break
							case 'shipping_phone':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'phone'
									) ||
									!requestBody.shipping_address.phone?.length
								) {
									requestBody.shipping_address.phone =
										'0000000000'
								}
								break
							case 'shipping_fax':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'shipping_address'
									)
								) {
									requestBody.shipping_address = {}
								}
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody.shipping_address,
										'fax'
									) ||
									!requestBody.shipping_address.fax?.length
								) {
									requestBody.shipping_address.fax =
										'0000000000'
								}
								break
							case 'national_tax_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'national_tax_amount'
									) ||
									!requestBody.national_tax_amount
								) {
									requestBody.national_tax_amount = 0
								}
								break
							case 'duty_amount':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'duty_amount'
									) ||
									!requestBody.duty_amount
								) {
									requestBody.duty_amount = 0
								}
								break
							case 'ship_from_postal_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'ship_from_postal_code'
									) ||
									!requestBody.ship_from_postal_code?.length
								) {
									requestBody.ship_from_postal_code = '00000'
								}
								break
							case 'summary_commodity_code':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'summary_commodity_code'
									) ||
									!requestBody.summary_commodity_code?.length
								) {
									requestBody.summary_commodity_code = 'null'
								}
								break
							case 'merchant_vat_registration_number':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'merchant_vat_registration_number'
									) ||
									!requestBody
										.merchant_vat_registration_number.length
								) {
									requestBody.merchant_vat_registration_number =
										'null'
								}
								break
							case 'customer_vat_registration_number':
								if (
									!Object.prototype.hasOwnProperty.call(
										requestBody,
										'customer_vat_registration_number'
									) ||
									!requestBody
										.customer_vat_registration_number.length
								) {
									requestBody.customer_vat_registration_number =
										'null'
								}
								break
							default:
								break
						}
					})
				}
				if (formData.name) {
					// only set the name to the form name if they are not an admin user
					if (username.value) {
						const userData: PeopleProperty = {
							email: formData.email,
							name: username.value,
							amount: mixpanelAmountFormat
						}
						mp.cardFormSetPeopleProp(userData)
					} else {
						const userData: PeopleProperty = {
							email: formData.email,
							name: formData.name,
							amount: mixpanelAmountFormat
						}
						mp.cardFormSetPeopleProp(userData)
					}
				}

				store.dispatch('submitTransaction', {
					requestBody: requestBody,
					grecaptchaAction: 'simplePayCardSubmit'
				})
			} catch (ex) {
				Sentry.captureException(ex)
				Sentry.captureMessage('SimplyPay Card Submit - Unknown Error')

				store.dispatch('hideLoadingScreen')
			}
		}
		async function getCustomFields() {
			try {
				const customFields = store.state.fpMerchantData.cardCustomFields
				if (customFields && customFields.length > 0) {
					hasCustomFields.value = true
					formData.customFields = customFields
				}
				const vaultField =
					store.state.fpMerchantData.vaultCustomFieldCard
				if (vaultField && vaultField.length > 0) {
					hasVaultField.value = true
					formData.vaultField = vaultField[0]
					formData.shouldVaultCustomer = true
					await changeEmailAndPhoneRequired()
					simplePayCardForm.value.clearValidate()
				}
			} catch (ex) {
				// gcf : get custom fields
				Sentry.captureException(ex)
				Sentry.captureMessage('gcf attempt failed')
			}
		}
		async function loadQueryParams() {
			const params = url.searchParams

			if (params.has('firstName')) {
				formData.firstName = params.get('firstName')
			}
			if (params.has('lastName')) {
				formData.lastName = params.get('lastName')
			}
			if (params.has('company')) {
				formData.company = params.get('company')
			}
			if (params.has('country')) {
				formData.country = params.get('country').toUpperCase()
			}
			if (params.has('state')) {
				const state = params.get('state')?.toUpperCase()
				if (!store.state.isStateless) {
					formData.state = state
				}
			}
			if (params.has('address')) {
				formData.address = params.get('address')
			}
			if (params.has('address2')) {
				formData.address2 = params.get('address2')
			}
			if (params.has('city')) {
				formData.city = params.get('city')
			}
			if (params.has('postalCode')) {
				formData.postalCode = params.get('postalCode')
			}
			if (params.has('email')) {
				formData.email = params.get('email')
			}
			if (params.has('phone')) {
				formData.phone = params.get('phone')
			}
			if (params.has('orderId')) {
				formData.orderId = params.get('orderId')
			}
			if (params.has('hideOrder')) {
				formData.hideOrder = params.get('hideOrder')
			}
			if (params.has('poNumber')) {
				formData.poNumber = params.get('poNumber')
			}
				if (params.has('hidePO')) {
				formData.hidePO = params.get('hidePO')
			}
			if (params.has('note')) {
				formData.note = params.get('note')
			}
			if (params.has('amount')) {
				const amountParam = params.get('amount')
				// scrub any non-digits out of the amount
				const scrubbedAmount = amountParam.replace(/\D/g, '')
				// format
				const decimalPosition = scrubbedAmount.length - 2
				const dollars = scrubbedAmount.slice(0, decimalPosition)
				const cents = scrubbedAmount.slice(decimalPosition)
				const formattedAmount = dollars + '.' + cents
				formData.amount = formattedAmount
			}
			// run field validation on the inputs with pre-filled values
			params.forEach((value, key) => {
				if (key !== 'id') {
					simplePayCardForm.value.validateField(key)
				}
			})
		}
		function validateDropdown() {
			simplePayCardForm.value.validateField('state')
		}
		async function changeEmailRequired() {
			store.dispatch(
				'toggleEmailRequiredOverride',
				formData.shouldEmailReceipt || formData.shouldVaultCustomer
			)
		}
		async function changeEmailAndPhoneRequired() {
			changeEmailRequired()
			store.dispatch(
				'togglePhoneRequiredOverride',
				formData.shouldVaultCustomer
			)
		}

		onMounted(async () => {
			simplePayCardForm.value.clearValidate()
			getCustomFields()
			loadQueryParams()
			getLocations()

			// get global var available through vue app
			const app = getCurrentInstance()
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)

			tokenizer.value = new Tokenizer({
				apikey: publicApiKey.value,
				url: baseUrl.value,
				container: document.querySelector('#card-container'),
				settings: {
					payment: {
						types: ['card'],
						showTitle: false
					},
					styles: {
						'.card>div:not(:last-child):not(.break)': {
							padding: '0 15px 0 0'
						},
						'.card .fieldset': {
							'margin-bottom': '14px',
							position: 'relative'
						},
						'.card .fieldset::before': {
							'line-height': '1em',
							padding: '0 0 7px 1px',
							display: 'inline-block',
							'font-size': '13px',
							color: bodyFontColor.value
						},
						'.card .cc::before': {
							content: '"Card Number"',
							'font-family': bodyFont.value
						},
						'.card .exp::before': {
							content: '"Exp Date"',
							'font-family': bodyFont.value,
							color: bodyFontColor.value
						},
						'.card input': {
							color: bodyFontColor.value
						},
						'.card .cvv::before': {
							content: '"CVV"',
							'font-family': bodyFont.value
						},
						'.card .cc .cc-icon': {
							height: '30px',
							padding: '0'
						},
						'.card .cc input::placeholder,.card .cvv input::placeholder':
							{
								color: bodyFontColor.value,
								opacity: '.5'
							},
						'.card .cc input::placeholder,.card .exp input::placeholder':
							{
								color: bodyFontColor.value,
								opacity: '.5'
							},
						'.card .cc input': {
							border: `solid 1px` + bodyFontColor.value
						},
						input: {
							border: `solid 1px` + bodyFontColor.value
						}
					}
				},
				onLoad: () => {
					store.dispatch('hideLoadingScreen')
				},
				submission: (resp) => {
					switch (resp.status) {
						case 'success':
							// Successful submission
							if (resp.token) {
								submitForm(resp.token)
							}
							break
						case 'error':
							// Encountered an error while performing submission
							Sentry.withScope(function (scope) {
								scope.setTag('simplepayid page', url.href)

								Sentry.captureMessage(
									'Tokenizer Error: Encountered an error while performing card submission'
								)
							})

							tokenizerError.value = resp.message
							window.scrollTo(0, 0)
							break
						case 'validation':
							// Encountered form validation specific errors
							tokenizerError.value =
								'Invalid card number. Please verify and try again.'
							window.scrollTo(0, 0)
							break
					}
				},
				validCard: (card: any) => {
					// when the user inputs the card number, grab the bin
					if (card?.bin) {
						cardBin.value = card.bin
					}
					// when they set the state, check the cardholder surcharge eligibility
					if (cardBin.value && formData.state?.length) {
						store.dispatch(
							'isCardholderSurchargeable',
							tokenizer.value.isSurchargeable(
								formData.state,
								cardBin.value
							)
						)
					}
				}
			})
		})

		return {
			formData,
			hasVaultField,
			changeEmailAndPhoneRequired,
			additionalInfoHeader,
			addressRequired,
			amountIsValid,
			changeEmailRequired,
			isEmailRequired,
			isPhoneRequired,
			companyLabel,
			companyRequired,
			convenienceFeeDisplayText,
			countryList,
			emailRequired,
			formValidationRules,
			hasAddress,
			hasCompany,
			hasEmail,
			hasConvenienceFee,
			hasNote,
			hasOrderId,
			hasPhone,
			hasPoNumber,
			hasServiceFee,
			hasSurcharge,
			orderIdLabel,
			orderIdRequired,
			merchantName,
			percentAdjRateDisplayText,
			phoneRequired,
			poNumberRequired,
			poNumberLabel,
			postalCodeValidationRules,
			stateValidationRules,
			presubmit,
			serviceFeeDisplayText,
			showAdditionalInfo,
			simplePayCardForm,
			stateList,
			tokenizerError,
			totalAmountDisplayText,
			updateStateList,
			validateDropdown,
			preventExcessDigitsOnAmount,
			trackFormTime,
			bodyFontColor,
			updateTokenizerState
		}
	}
})
