
import { defineComponent, computed, ref } from 'vue'
import { useStore } from '../../store'

export default defineComponent({
	name: 'SimplePayHeader',
	props: {
		headerColor: {
			type: String,
			default: '#fff'
		},
		headerFontColor: {
			type: String,
			default: '#222'
		}
	},
	setup() {
		const store = useStore()
		const publicPath = ref(process.env.BASE_URL)
		const iqProLogo = computed((): string => {
			return store.state.logos.iqProLogoNavy
		})
		const merchantLogo = computed((): string => {
			let merchantLogo = store.state.iqMerchantData.simplePaySettings.logo

			return merchantLogo ?? ''
		})
		const enlargeLogo = computed((): boolean => {
			return store.state.iqMerchantData.simplePaySettings.enlargeLogo
		})
		const merchantName = computed((): string => {
			let merchantName =
				store.state.iqMerchantData.simplePaySettings.merchantName

			return merchantName ?? ''
		})
		const headerText = computed((): string => {
			let headerText =
				store.state.iqMerchantData.simplePaySettings.headerText

			return headerText ?? ''
		})
		const formIsActive = computed((): boolean => {
			return store.state.iqMerchantData.simplePaySettings.isActive
		})

		return {
			publicPath,
			iqProLogo,
			merchantLogo,
			enlargeLogo,
			merchantName,
			headerText,
			formIsActive
		}
	}
})
