<template>
	<div>
		<h2>Thank you, your payment has been processed.</h2>
		<ul>
			<li>
				<b>Approval Code:&nbsp;</b>
				{{ authCode }}
			</li>
			<li>
				<b>Transaction ID:&nbsp;</b>
				{{ confirmationNumber }}
			</li>
			<li>
				<b>Processed on:&nbsp;</b>
				{{ timestamp }}
			</li>
			<li>
				<b>Amount:&nbsp;</b>
				{{ formatAmountUSD }}
			</li>
		</ul>
		<el-button
			v-if="!backToHomeOverrideUrl"
			ref="homeButton"
			type="primary"
			size="small"
			class="float-r"
			@click="handleAnotherPayment"
			>Make another payment</el-button
		>
		<el-button
			v-if="backToHomeOverrideUrl"
			ref="homeButton"
			type="primary"
			size="small"
			class="float-r"
			@click="redirect"
			>Back</el-button
		>
	</div>
</template>

<script lang="ts">
import mixpanel from 'mixpanel-browser'
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	ref
} from 'vue'
import { useStore } from '../store'
import * as mp from '../apis/mixpanel'

export default defineComponent({
	name: 'SimplePaySuccess',
	emits: ['reset-simplepay'],
	setup(props, context) {
		const store = useStore()
		const distinctId = ref('')

		const handleAnotherPayment = () => {
			mp.makeAnotherPayment()
			context.emit('reset-simplepay')
		}

		const authCode = computed(() => store.state.transactionData.authCode)
		const confirmationNumber = computed(
			() => store.state.transactionData.confirmationNumber
		)
		const timestamp = computed(() => store.state.transactionData.timestamp)
		const transactionAmount = computed(
			() => store.state.transactionData.transactionAmount
		)
		const backToHomeOverrideUrl = computed(
			() =>
				store.state.iqMerchantData.simplePaySettings
					.backToHomeOverrideUrl
		)
		const formatAmountUSD = computed((): string => {
			const amount = transactionAmount.value

			if (!amount) return '-'

			return Number(amount / 100).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'symbol'
			})
		})

		function redirect() {
			const uri = encodeURI(backToHomeOverrideUrl.value)

			window.location.replace(uri)
		}

		onMounted(() => {
			const app = getCurrentInstance()
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
			mp.successViewCountPeopleProp()
		})

		return {
			authCode,
			confirmationNumber,
			timestamp,
			formatAmountUSD,
			transactionAmount,
			backToHomeOverrideUrl,
			redirect,
			handleAnotherPayment
		}
	}
})
</script>

<style lang="scss" scoped>
ul {
	list-style: circle;
	list-style-position: inside;
	margin: 20px 0;
	padding: 0;

	li {
		margin: 5px 0;
	}
}

@media screen and (max-width: 600px) {
	.el-button {
		width: 100%;
	}
}
</style>
