<template>
	<div>
		<h2>This page has been deactivated</h2>
		<p>Please try again later.</p>
	</div>
</template>

<script lang="ts">
export default {
	name: 'SimplePayInactive'
}
</script>

<style lang="scss" scoped></style>
