
import {
	defineComponent,
	ref,
	computed,
	onMounted,
	getCurrentInstance
} from 'vue'
import { useStore } from '../store'
import SimplePayLayout from '../global-components/layouts/SimplePayLayout.vue'
import SimplePayCardForm from '../global-components/SimplePayCardForm.vue'
import SimplePayAchForm from '../global-components/SimplePayAchForm.vue'
import SimplePaySuccess from '../global-components/SimplePaySuccess.vue'
import SimplePayInactive from '../global-components/SimplePayInactive.vue'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'
import router from '@/router'

export default defineComponent({
	name: 'SimplePayHostedPaymentView',
	components: {
		SimplePayLayout,
		SimplePayCardForm,
		SimplePayAchForm,
		SimplePaySuccess,
		SimplePayInactive
	},
	setup() {
		const store = useStore()
		// initialize as null string because certain props look at length if there's no id in url
		const simplePayId = ref('null')
		const initComplete = ref(false)
		const distinctId = ref('')
		const hideGdpr = ref(true)

		const hasCardForm = computed((): boolean => {
			return (
				store.state.iqMerchantData.simplePaySettings.hasCardForm ===
				true
			)
		})
		const hasAchForm = computed((): boolean => {
			return (
				store.state.iqMerchantData.simplePaySettings.hasAchForm === true
			)
		})
		const formIsActive = computed((): boolean => {
			return (
				store.state.iqMerchantData.simplePaySettings.isActive === true
			)
		})
		const userView = computed({
			get: (): string => store.state.simplePayUserView,
			set: (selectedView: string): void => {
				// default to card if an invalid userView is passed
				selectedView =
					selectedView === 'ach' ||
					selectedView === 'inactive' ||
					selectedView === 'success'
						? selectedView
						: 'card'
				// pass userView to mixpanel
				formViews(selectedView)
				// set userView in store
				store.commit('simplePayUserView', selectedView)
			}
		})
		const hasBanner = computed((): boolean => {
			return store.state.iqMerchantData.simplePaySettings.hasBanner
		})
		const bannerType = computed((): string => {
			return store.state.iqMerchantData.simplePaySettings.bannerType
		})
		const bannerMessage = computed((): string => {
			return store.state.iqMerchantData.simplePaySettings.bannerMessage
		})
		const acceptedPaymentTypes = computed((): string[] => {
			let cards =
				store.state.iqMerchantData.simplePaySettings.acceptedCards
			let formattedCards: string[] = []

			cards = Array.isArray(cards) ? cards : []

			function stringUpper(card: string): string {
				return card.toUpperCase()
			}

			if (cards.length) {
				let uppercaseCards = cards.map(stringUpper)
				// pushing values into the array manually to enforce a specific order and allow for varied capitalization

				if (uppercaseCards.indexOf('VISA') > -1) {
					formattedCards.push('Visa')
				}

				if (uppercaseCards.indexOf('MASTERCARD') > -1) {
					formattedCards.push('MasterCard')
				}

				if (uppercaseCards.indexOf('DISCOVER') > -1) {
					formattedCards.push('Discover')
				}

				if (uppercaseCards.indexOf('AMERICAN EXPRESS') > -1) {
					formattedCards.push('American Express')
				}

				if (store.state.iqMerchantData.simplePaySettings.hasAchForm) {
					formattedCards.push('ACH')
				}
			}

			return formattedCards
		})
		const simplePayCssProps = computed(() => {
			return {
				'--body-font':
					store.state.iqMerchantData.simplePaySettings.bodyFont ??
					'Avenir',
				'--headline-font':
					store.state.iqMerchantData.simplePaySettings.headerFont ??
					'Avenir',
				'--body-font-color':
					store.state.iqMerchantData.simplePaySettings
						.bodyFontColor ?? '#6d6c6b',
				'--headline-font-color':
					store.state.iqMerchantData.simplePaySettings
						.headerFontColor ?? '#6d6c6b',
				'--bg-color':
					store.state.iqMerchantData.simplePaySettings
						.backgroundColor,
				'--form-background':
					store.state.iqMerchantData.simplePaySettings
						.formBackgroundColor
			}
		})

		function getUrlParameter(paramName: string): string {
			paramName = paramName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null
				? 'null_id'
				: decodeURIComponent(results[1].replace(/\+/g, ' '))
		}
		function initForm(): void {
			initComplete.value = true // card and ach forms v-if condition is met
			let displayedForm = 'inactive'
			// check if cookie from previous visit exists
			const isReturningUser = hasVisitorCookie()

			if (!isReturningUser) {
				// if user has not visited before, reveal gdpr banner
				hideGdpr.value = false
				// set the cookie so the banner will stay hidden on next visit
				setVisitorCookie()
			}

			if (formIsActive.value) {
				displayedForm =
					hasAchForm.value && !hasCardForm.value ? 'ach' : 'card'
			}

			userView.value = displayedForm // selected form v-show condition is met
			// get that initial view count
		}
		async function resetForm() {
			await store.dispatch('showLoadingScreen')
			// set initComplete to false again in case the user is submitting a second payment
			// this will force the card and ach forms to re-mount
			initComplete.value = false
			await store.dispatch('clearTransactionDetails')
			initForm()
		}
		function optOutMixpanel(): void {
			mp.optUserOut()
			dismissGdprBanner()
		}
		function dismissGdprBanner(): void {
			hideGdpr.value = true
		}
		function setVisitorCookie(): void {
			localStorage.setItem('prevSimplepayUser', 'true')
		}
		function hasVisitorCookie(): boolean {
			return localStorage.getItem('prevSimplepayUser') === 'true'
		}
		function clearBanner(): void {
			store.commit('clearBanner')
		}
		function formViews(selectedView: string) {
			// capture view changes after page is loaded
			// adds view count to users profile in mixpanel
			switch (selectedView) {
				case 'card':
					mp.cardViewCountPeopleProp()
					mp.cardFormPageViewTrack()
					break
				case 'ach':
					mp.achViewCountPeopleProp()
					mp.achFormPageViewTrack()
					break
				case 'inactive':
					mp.inactiveViewCountPeopleProp()
					mp.inactivePageViewTrack(simplePayId.value)
					break
				case 'success':
					break
				default:
					break
			}
		}

		onMounted(async () => {
			simplePayId.value = getUrlParameter('id')

			// identifies user, associates the user with the simplepay group, tracks page view
			const app = getCurrentInstance()
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
			await store.dispatch('setDistinctId', distinctId.value)

			mp.setSimplePayGroupId(simplePayId.value)

			try {
				if (simplePayId.value === 'null_id') {
					router.push({ name: 'sp-page-not-found' })
					mp.pageNotFoundPageViewTrack(simplePayId.value)
				}
				await store.dispatch('initSimplePay', simplePayId.value)
				// initForm() will initialize SimplePayCardForm and/or SimplePayAchForm due to the v-if directive in the template.
				// Those child components require the PublicApiKey to be set in order to initialize the tokenizer,
				// so initForm() needs to be fired after the merchant data has been retrieved from the apis and set in the store.
				initForm()
			} catch (ex) {
				console.log('init failed', ex)
			}
		})

		return {
			simplePayId,
			hasCardForm,
			hasAchForm,
			formIsActive,
			initComplete,
			userView,
			hasBanner,
			bannerType,
			bannerMessage,
			acceptedPaymentTypes,
			simplePayCssProps,
			resetForm,
			getUrlParameter,
			clearBanner,
			hideGdpr,
			optOutMixpanel,
			dismissGdprBanner
		}
	}
})
