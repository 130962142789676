
import mixpanel from 'mixpanel-browser'
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	ref
} from 'vue'
import { useStore } from '../store'
import * as mp from '../apis/mixpanel'

export default defineComponent({
	name: 'SimplePaySuccess',
	emits: ['reset-simplepay'],
	setup(props, context) {
		const store = useStore()
		const distinctId = ref('')

		const handleAnotherPayment = () => {
			mp.makeAnotherPayment()
			context.emit('reset-simplepay')
		}

		const authCode = computed(() => store.state.transactionData.authCode)
		const confirmationNumber = computed(
			() => store.state.transactionData.confirmationNumber
		)
		const timestamp = computed(() => store.state.transactionData.timestamp)
		const transactionAmount = computed(
			() => store.state.transactionData.transactionAmount
		)
		const backToHomeOverrideUrl = computed(
			() =>
				store.state.iqMerchantData.simplePaySettings
					.backToHomeOverrideUrl
		)
		const formatAmountUSD = computed((): string => {
			const amount = transactionAmount.value

			if (!amount) return '-'

			return Number(amount / 100).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'symbol'
			})
		})

		function redirect() {
			const uri = encodeURI(backToHomeOverrideUrl.value)

			window.location.replace(uri)
		}

		onMounted(() => {
			const app = getCurrentInstance()
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
			mp.successViewCountPeopleProp()
		})

		return {
			authCode,
			confirmationNumber,
			timestamp,
			formatAmountUSD,
			transactionAmount,
			backToHomeOverrideUrl,
			redirect,
			handleAnotherPayment
		}
	}
})
