
import { defineComponent, computed } from 'vue'
import { useStore } from '../../store'
import SimplePayHeader from '../headers/SimplePayHeader.vue'
import SimplePayFooter from '../footers/SimplePayFooter.vue'
import LoadingOverlay from '../LoadingOverlay.vue'

export default defineComponent({
	components: {
		SimplePayHeader,
		SimplePayFooter,
		LoadingOverlay
	},
	setup() {
		// initialize store
		const store = useStore()
		// set variables with state from store
		const isLoading = computed((): boolean => store.state.isLoading)
		const primaryColor = computed((): string => {
			let color =
				store.state.iqMerchantData.simplePaySettings.primaryColor
			color = color?.length ? prependHash(color.toLowerCase()) : '#fff'
			return color
		})
		const secondaryColor = computed((): string => {
			let color =
				store.state.iqMerchantData.simplePaySettings.secondaryColor
			color = color?.length ? prependHash(color.toLowerCase()) : ''
			return color
		})

		// logic for determining header color
		const isPrimaryColorWhite = computed((): boolean => {
			return (
				primaryColor.value === '#fff' ||
				primaryColor.value === '#ffffff'
			)
		})
		const isPrimaryColorLight = computed((): boolean => {
			// convert to hsl to check lightness value
			const primaryColorHsl = hexToHsl(primaryColor.value)
			// if the lightness channel is above 60%, consider it a light color
			return primaryColorHsl.l > 60
		})
		const headerFontColor = computed((): string => {
			// if the header color is light swap the font color for contrast
			return isPrimaryColorLight.value ? '#222' : '#fff'
		})
		// logic for determining bg color
		const hasSecondaryColor = computed((): boolean => {
			// check if the merchant has designated a secondary color different than the primary color
			return secondaryColor.value?.length &&
				secondaryColor.value !== primaryColor.value
				? true
				: false
		})
		const secondaryColorHsl = computed((): any => {
			// If the merchant has selected a secondary color different than the primary, use it
			let color = secondaryColor.value
			// If there is no unique secondary color set, use the primary color
			if (!hasSecondaryColor.value) {
				// generate a background based on the primary color
				// if the primary color is white, use the default blue to avoid a gray bg
				color = isPrimaryColorWhite.value
					? '#bce2f1'
					: primaryColor.value
			}
			// convert to hsl color space and return
			return hexToHsl(color)
		})
		const backgroundColor = computed((): string => {
			let hslBase = secondaryColorHsl.value
			// if color saturation is above 65%, reduce it to that level to avoid an over-saturated background color that competes visually with the form
			hslBase.s = hslBase.s > 65 ? 65 : hslBase.s

			if (
				!hasSecondaryColor.value &&
				isPrimaryColorLight.value &&
				!isPrimaryColorWhite.value
			) {
				// if the secondary color hasn't been specified, and the primary color is light but not white, decrease the lightness to contrast with the header
				hslBase.l -= 20
			} else if (!hasSecondaryColor.value && !isPrimaryColorLight.value) {
				// if the secondary color hasn't been specified, and the primary color is not very light, increase the lightness to contrast with the header
				hslBase.l += 20
			} else {
				// if the secondary color has been specified, and the color lightness is above 85%, reduce it to that level
				hslBase.l = hslBase.l > 85 ? 85 : hslBase.l
			}
			// create the first gradient color
			let hslColor1 = {
				h: hslBase.h,
				s: hslBase.s,
				l: hslBase.l
			}
			// create the second gradient color
			let hslColor2 = {
				h: hslBase.h - 5,
				s: hslBase.s - 3,
				l: hslBase.l + 3
			}
			// account for hue manipulation introducing a negative value
			if (hslBase.h < 5) {
				hslColor2.h = 355 + hslBase.h
			}
			// convert back to hex so the CSS variable doesn't freak out
			let color = hslToHex(hslColor1.h, hslColor1.s, hslColor1.l)
			let color2 = hslToHex(hslColor2.h, hslColor2.s, hslColor2.l)

			return (
				'linear-gradient(170deg, ' +
				color +
				' 0%, ' +
				color2 +
				' 40%, ' +
				color2 +
				' 55%, ' +
				color +
				' 100%)'
			)
		})
		// convert a hex color string to an object with hsl color properties
		function prependHash(color: string): string {
			// if the hex string doesn't start with a hash, add one
			return color.charAt(0) === '#' ? color : '#' + color
		}
		function hexToHsl(H: string): any {
			if (H === undefined || H === null) {
				return {
					h: 0,
					s: 0,
					l: 100
				}
			}
			// Convert hex to RGB first
			let r = '',
				g = '',
				b = ''

			if (H.length == 4) {
				r = '0x' + H[1] + H[1]
				g = '0x' + H[2] + H[2]
				b = '0x' + H[3] + H[3]
			} else if (H.length == 7) {
				r = '0x' + H[1] + H[2]
				g = '0x' + H[3] + H[4]
				b = '0x' + H[5] + H[6]
			}
			// Then to HSL
			let newR: number = Number(r)
			let newG: number = Number(g)
			let newB: number = Number(b)

			newR /= 255
			newG /= 255
			newB /= 255

			let cmin = Math.min(newR, newG, newB),
				cmax = Math.max(newR, newG, newB),
				delta = cmax - cmin,
				h = 0,
				s = 0,
				l = 0

			if (delta == 0) h = 0
			else if (cmax == newR) h = ((newG - newB) / delta) % 6
			else if (cmax == newG) h = (newB - newR) / delta + 2
			else h = (newR - newG) / delta + 4

			h = Math.round(h * 60)

			if (h < 0) h += 360

			l = (cmax + cmin) / 2
			s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
			s = +(s * 100).toFixed(1)
			l = +(l * 100).toFixed(1)

			return {
				h: h,
				s: s,
				l: l
			}
		}
		// convert an object with hsl color properties to a hex color string
		function hslToHex(h: number, s: number, l: number): string | null {
			// a null value should be impossible because of logic earlier in the chain, but check just in case so it'll throw an easy-to-find error
			if (
				h === undefined ||
				h === null ||
				s === undefined ||
				s === null ||
				l === undefined ||
				l === null
			) {
				return null
			}

			s /= 100
			l /= 100

			let c = (1 - Math.abs(2 * l - 1)) * s,
				x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
				m = l - c / 2,
				r = 0,
				g = 0,
				b = 0

			if (0 <= h && h < 60) {
				r = c
				g = x
				b = 0
			} else if (60 <= h && h < 120) {
				r = x
				g = c
				b = 0
			} else if (120 <= h && h < 180) {
				r = 0
				g = c
				b = x
			} else if (180 <= h && h < 240) {
				r = 0
				g = x
				b = c
			} else if (240 <= h && h < 300) {
				r = x
				g = 0
				b = c
			} else if (300 <= h && h < 360) {
				r = c
				g = 0
				b = x
			}
			// Having obtained RGB, convert channels to hex
			r = Math.round((r + m) * 255)
			g = Math.round((g + m) * 255)
			b = Math.round((b + m) * 255)

			let newR = r.toString(16)
			let newG = g.toString(16)
			let newB = b.toString(16)

			if (newR.length == 1) {
				newR = '0' + newR
			}
			if (newG.length == 1) {
				newG = '0' + newG
			}
			if (newB.length == 1) {
				newB = '0' + newB
			}
			return '#' + newR + newG + newB
		}

		return {
			isLoading,
			backgroundColor,
			primaryColor,
			headerFontColor,
			secondaryColor
		}
	}
})
